import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { CiMenuBurger } from 'react-icons/ci';
import { TbSettingsCog } from 'react-icons/tb'
import { Carousel } from 'react-bootstrap';

const Digital_marketing_for_e_commerce = () => {
  const [menubar, setmenubar] = useState(false)
  const [menubar1, setmenubar1] = useState(false)

  const setmenubarFunction = () => {
      setmenubar(!menubar);
      setmenubar1(!menubar1);
  }

  const [navchange, setNavbar] = useState(false);



  const changebackground = () => {
      if (window.scrollY >= 250) {
          setNavbar(true)
      }
      else {
          setNavbar(false);
      }
  }

  window.addEventListener('scroll', changebackground)
  return (
      <>
          <Carousel className='carouse' indicators={false} controls={false}>
              <Carousel.Item className='carouse1' interval={1000}>
                  <img
                      className="d-block w-100"
                      src="banner.jpg"
                      alt="First slide"
                  />
                  <Carousel.Caption className='caroselCaption'>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <Link className='p-2 text-center text' to='/Home' style={{ textDecoration: 'none' }}><h6>HOME</h6></Link>
                          <h6 className='p-2 text' > - </h6>
                          <Link className='p-2' style={{ textDecoration: 'none', color: 'white' }}><h6>ECommerce</h6></Link>
                      </div>
                      <h3 className='display-1'><b>ECommerce</b></h3>

                  </Carousel.Caption>
              </Carousel.Item>

          </Carousel>
          {/* <div className='carouse' >
            <div className='banner' >
                <img src='banner.jpg' alt='' style={{ width: '100%',height:'400px' }}></img>
            </div>
            <div className='container text-center linkItems' style={{ position: 'absolute' }} >
                <div className='text-center' style={{ display: 'flex'}}>
                    <Link className='p-2 text-center' to='/Home'>Home </Link>
                    <p className='p-2'> - </p>
                    <Link className='p-2'>Services</Link>
                </div>

            </div>

        </div> */}
          <div className='navs fixed-top shadow-lg  rounded'>
              <nav className={navchange ? 'navbar active' : 'navbar'} >
                  <div class="container-fluid">
                      <div class="navbar-header">
                          <Link className='navbar-brand'><img src='white logo final DART LOGO.png' alt='' style={{ width: '150px', height: '80px', paddingTop: '0px', marginTop: '0px' }}></img></Link>
                      </div>
                      <div className='megaLinks'>
                          <ul className={menubar ? 'nav-links' : 'nav-link'} style={{ listStyleType: 'none',marginTop:'15px' }}>
                              <li >
                                  <Link to="/" style={{ textDecoration: 'none', marginLeft: '50px', color: 'white' }}><b>HOME</b></Link>
                              </li>
                              <li>
                                  <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white' }}><b>ABOUT</b></Link>
                              </li>
                              <li>
                                  <Link to="/Services" style={{ textDecoration: 'none', color: 'white' }}><b>SERVICES</b></Link>
                              </li>
                             
                              <li>
                                  <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white' }}><b>INDUSTRIES</b></Link>
                              </li>
                              <li>
                                  <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white' }}><b>BLOGS</b></Link>
                              </li>
                              <li>
                                  <Link to="/Contact" style={{ textDecoration: 'none', color: 'white' }}><b>CONTACT US</b></Link>
                              </li>
                          </ul>
                      </div>

                      <div className='ms-auto'>
                          <CiMenuBurger onClick={setmenubarFunction} style={{ margin: '30px', width: '30px', height: '30px', color: 'white' }} />
                      </div>
                  </div>


              </nav>
              <div className={menubar1 ? 'nav-linked' : 'naved'} >
                  <ul style={{ listStyleType: 'none', alignContent: 'center' }} >
                      <li >
                          <Link to="/" style={{ textDecoration: 'none', color: 'white', textAlign: 'center', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}><b>HOME</b></Link>
                      </li>
                      <li>
                          <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>ABOUT</Link>
                      </li>
                      <li>
                          <Link to="/Services" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>SERVICES</Link>
                      </li>
                     
                      <li>
                          <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px'  }}><b>INDUSTRIES</b></Link>
                      </li>
                      <li>
                          <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>BLOGS</Link>
                      </li>
                      <li>
                          <Link to="/Contact" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>CONTACT US</Link>
                      </li>
                  </ul>

                  <div className='container mt-5 p-5' >
                        <div className='row'>
                            <div className='col' style={{ color: 'black' }}>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Phone: 093934 15155</h4>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Email: dartmarketing.in@gmail.com</h4>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '17px' }}>Address:11/2, D.No. 6-11-53/A, 1st Floor, Arundelpet, Guntur, IN - 522002</h4>
                            </div>

                            <div className='container col text-center' style={{ color: 'white', textAlign: 'center' }}>
                                <ul style={{ listStyleType: 'none', display: 'flex' }}>
                                    <li>
                                        <Link to='https://www.facebook.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>facebook</Link></li>
                                    <li><Link to='https://www.instagram.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>youtube</Link></li>
                                    <li><Link style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>twitter</Link></li>

                                </ul>
                            </div>
                        </div>
                    </div>
              </div>

          </div>
          <div className='container mt-5'>
          <h1 className='secTitle  text-center mb-4' style={{ display: 'flex', justifyContent: 'center', fontSize: '44px', marginTop: '-23px' }}>Digital Marketing Services for eCommerce in Guntur</h1>
              <h4 className='mb-4' style={{ color: 'red' }}>Digital marketing for profitable and effective customer engagement</h4>
              <h5 className='mb-4'>Better and Effective Customer engagement</h5>
              <p className='container para mb-4' style={{ color: 'grey' }}>Your customers are better engaged with our attractive results-oriented campaigns that create the better and right digital presence for your company.</p>
              <h4 className='mb-4' style={{ color: 'red' }}>eCommerce SEO Services “Search Engine Optimization”:</h4>
              <h5 className='mb-4'>Digital Marketing Services for eCommerce:</h5>
              <p className='container para mb-4' style={{ color: 'grey' }}>SEO is the most important and fundamental component of eCommerce. Our resourceful team, with their proven SEO techniques ensures that your website is on top of the list of results by the search engines. We identify the targets for your website and by using specific keywords in the content attract the search engines. Our team also performs regular monthly audits and performance analysis so that you get delivered top search engine rankings.</p>
              <h5 className='mb-4'>eCommerce Social Media Strategy & Marketing:</h5>
              <p className='container para mb-4' style={{ color: 'grey' }}>A dedicated team of experienced SMM experts regularly work with you to gain traffic through social media channels like Facebook, Twitter, Instagram and others. The campaigns can either be organic through the social media presence or it can be paid by using advertising. Our team pairs these campaigns with SEO and SEM. We provide monthly analytics for the social media networks.</p>
              <h5 className='mb-4'>E-Commerce Content Marketing Strategy</h5>
              <p className='container para mb-4' style={{ color: 'grey' }}>Content marketing is a strategic marketing approach where content is created, distributed, in a valuable and consistent way to attract and retain a defined audience. Sales and customer satisfaction is the end result of this marketing approach. Another form of content marketing and messaging is distributed content, which gets published on the outside website driving traffic back to your website. The main aim here is to capture the target audience and also to amplify the message driving brand awareness.</p>
          </div>
      </>
  )
}

export default Digital_marketing_for_e_commerce