import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { CiMenuBurger } from 'react-icons/ci';
import { TbSettingsCog } from 'react-icons/tb'
import { Carousel } from 'react-bootstrap';

const ABOUT = () => {
    const [menubar, setmenubar] = useState(false)
    const [menubar1, setmenubar1] = useState(false)

    const setmenubarFunction = () => {
        setmenubar(!menubar);
        setmenubar1(!menubar1);
    }

    const [navchange, setNavbar] = useState(false);



    const changebackground = () => {
        if (window.scrollY >= 250) {
            setNavbar(true)
        }
        else {
            setNavbar(false);
        }
    }

    window.addEventListener('scroll', changebackground)
    return (
        <>
            <Carousel className='carouse' indicators={false} controls={false}>
                <Carousel.Item className='carouse1' interval={1000}>
                    <img
                        className="d-block w-100"
                        src="banner.jpg"
                        alt="First slide"
                    />
                    <Carousel.Caption className='caroselCaption'>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Link className='p-2 text-center text' to='/Home' style={{ textDecoration: 'none' }}><h6>HOME</h6></Link>
                            <h6 className='p-2 text' > - </h6>
                            <Link className='p-2' style={{ textDecoration: 'none', color: 'white' }}><h6>SERVICES</h6></Link>
                        </div>
                        <h3 className='display-1'><b>ABOUT</b></h3>

                    </Carousel.Caption>
                </Carousel.Item>

            </Carousel>
            {/* <div className='carouse' >
              <div className='banner' >
                  <img src='banner.jpg' alt='' style={{ width: '100%',height:'400px' }}></img>
              </div>
              <div className='container text-center linkItems' style={{ position: 'absolute' }} >
                  <div className='text-center' style={{ display: 'flex'}}>
                      <Link className='p-2 text-center' to='/Home'>Home </Link>
                      <p className='p-2'> - </p>
                      <Link className='p-2'>Services</Link>
                  </div>

              </div>

          </div> */}
            <div className='navs fixed-top shadow-lg  rounded'>
                <nav className={navchange ? 'navbar active' : 'navbar'} >
                    <div class="container-fluid">
                        <div class="navbar-header">
                            <Link className='navbar-brand'><img src='white logo final DART LOGO.png' alt='' style={{ width: '150px', height: '80px', paddingTop: '0px', marginTop: '0px' }}></img></Link>
                        </div>
                        <div className='megaLinks'>
                            <ul className={menubar ? 'nav-links' : 'nav-link'} style={{ listStyleType: 'none' ,marginTop:'15px'}}>
                                <li >
                                    <Link to="/" style={{ textDecoration: 'none', marginLeft: '50px', color: 'white' }}><b>HOME</b></Link>
                                </li>
                                <li>
                                    <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white' }}><b>ABOUT</b></Link>
                                </li>
                                <li>
                                    <Link to="/Services" style={{ textDecoration: 'none', color: 'white' }}><b>SERVICES</b></Link>
                                </li>
                               
                                <li>
                                    <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white' }}><b>INDUSTRIES</b></Link>
                                </li>
                                <li>
                                    <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white' }}><b>BLOGS</b></Link>
                                </li>
                                <li>
                                    <Link to="/Contact" style={{ textDecoration: 'none', color: 'white' }}><b>CONTACT US</b></Link>
                                </li>
                            </ul>
                        </div>

                        <div className='ms-auto'>
                            <CiMenuBurger onClick={setmenubarFunction} style={{ margin: '30px', width: '30px', height: '30px', color: 'white' }} />
                        </div>
                    </div>


                </nav>
                <div className={menubar1 ? 'nav-linked' : 'naved'} >
                    <ul style={{ listStyleType: 'none', alignContent: 'center' }} >
                        <li >
                            <Link to="/" style={{ textDecoration: 'none', color: 'white', textAlign: 'center', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}><b>HOME</b></Link>
                        </li>
                        <li>
                            <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>ABOUT</Link>
                        </li>
                        <li>
                            <Link to="/Services" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>SERVICES</Link>
                        </li>
                       
                        <li>
                            <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white',fontWeight: 'bold', fontSize: '20px' }}><b>INDUSTRIES</b></Link>
                        </li>
                        <li>
                            <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>BLOGS</Link>
                        </li>
                        <li>
                            <Link to="/Contact" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>CONTACT US</Link>
                        </li>
                    </ul>

                    <div className='container mt-5 p-5' >
                        <div className='row'>
                            <div className='col' style={{ color: 'black' }}>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Phone: 093934 15155</h4>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Email: dartmarketing.in@gmail.com</h4>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '17px' }}>Address:11/2, D.No. 6-11-53/A, 1st Floor, Arundelpet, Guntur, IN - 522002</h4>
                            </div>

                            <div className='container col text-center' style={{ color: 'white', textAlign: 'center' }}>
                                <ul style={{ listStyleType: 'none', display: 'flex' }}>
                                    <li>
                                        <Link to='https://www.facebook.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>facebook</Link></li>
                                    <li><Link to='https://www.instagram.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>youtube</Link></li>
                                    <li><Link style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>twitter</Link></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
            <p className='mb-4 mt-4' style={{color:'gray'}}>In today's digital age, a strong online presence is essential for businesses to thrive. Whether you're a budding entrepreneur or an established brand, your journey to success begins with an effective digital marketing strategy. This is where Dart Marketing Solutions Pvt Ltd comes in as your trusted partner, offering a comprehensive range of services to elevate your online presence and drive business growth.</p>
            <p className='mb-4' ><b>Our Services</b></p>
            <p className='mb-4' style={{color:'gray'}}>At Dart Marketing Solutions Pvt Ltd, we offer a diverse set of services that cater to all your digital marketing needs:</p>
            <p className='mb-4' style={{color:'gray'}}><b>Web Designing: </b>Your website is often the first interaction customers have with your brand. We create stunning, user-friendly websites that not only capture your brand's essence but also deliver a seamless user experience. A well-designed website is the cornerstone of your online presence.</p>
            <p className='mb-4' style={{color:'gray'}}><b>Graphic Designing: </b>Eye-catching visuals and graphics play a pivotal role in conveying your brand's message. Our expert graphic designers craft captivating visuals that resonate with your audience, helping your brand stand out in a crowded digital landscape.</p>
            <p className='mb-4' style={{color:'gray'}}><b>Digital Marketing: </b>Our digital marketing experts employ cutting-edge strategies to boost your online visibility. From search engine optimization (SEO) to pay-per-click (PPC) advertising and social media marketing, we ensure that your brand reaches the right audience, at the right time.</p>
            <p className='mb-4' style={{color:'gray'}}><b>Video Production: </b>Video content is the future of online marketing. We produce high-quality videos that tell your brand's story and engage your audience. From promotional videos to educational content, we've got your video production needs covered.</p>
            <p className='mb-4' style={{color:'gray'}}><b>SEO (Search Engine Optimization): </b>Ranking on the top of search engine results is crucial for online success. Our SEO specialists optimize your website to improve its search engine rankings, driving organic traffic and increasing your brand's visibility.</p>
            <p className='mb-4' ><b>Why Choose Dart Marketing Solutions Pvt Ltd?</b></p>
            <p className='mb-4' style={{color:'gray'}}><b>Expertise: </b>With a dedicated team of professionals, we bring years of experience and expertise to the table. We understand the nuances of the Guntur market and the ever-changing digital landscape.</p>
            <p className='mb-4' style={{color:'gray'}}><b>Customized Solutions: </b>We don't believe in one-size-fits-all solutions. Our team tailors strategies and designs to meet your specific business needs and goals.</p>
            <p className='mb-4' style={{color:'gray'}}><b>Data-Driven Approach: </b>We believe in the power of data. Our strategies are data-driven, ensuring that we continuously adapt and refine our approach to achieve optimal results.</p>
            <p className='mb-4' style={{color:'gray'}}><b>Client-Centric: </b>We prioritize our clients' success. Your goals become our goals, and we work tirelessly to help you achieve them.</p>
            <p className='mb-4' style={{color:'gray'}}><b>Affordable Pricing: </b>We offer competitive pricing that suits businesses of all sizes, from startups to enterprises.</p>
            <p className='mb-4' style={{color:'gray'}}>At Dart Marketing Solutions Pvt Ltd, we are committed to helping your brand succeed in the digital world. With our comprehensive services, experienced team, and client-centric approach, we are your go-to digital marketing agency in Guntur. Your journey to digital success begins here.</p>
            <p className='mb-4' style={{color:'gray'}}>Ready to elevate your online presence? Contact us today, and let's start your digital success story with Dart Marketing Solutions Pvt Ltd!</p>
           
            </div>
        </>
    )
}

export default ABOUT