

import './App.css';

import Header from './Header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Footer from './Footer';
import Services from './Services';
import Contact from './Contact';
import INDUSTRIES from './INDUSTRIES';
import SEO_Services_in_Guntur from './SEO_Services_in_Guntur';
import Social_Media_Marketing_in_Guntur from './Social_Media_Marketing_in_Guntur';
import PPC_Marketing_services_in_guntur from './PPC_Marketing_services_in_guntur';
import Content_Analysis_in_Guntur from './Content_Analysis_in_Guntur';
import Graphic_Designing_Services_in_Guntur from './Graphic_Designing_Services_in_Guntur';
import Web_Design_Development_Services_in_Guntur from './Web_Design_Development_Services_in_Guntur';
import Branding_Services_In_Guntur from './Branding_Services_In_Guntur';
import E_Commerce_Websites_Development_Services_Guntur from './E_Commerce_Websites_Development_Services_Guntur';
import Logo_Designing_Services_in_Guntur from './Logo_Designing_Services_in_Guntur';
import Digital_Marketing_For_Real_Estate_Guntur from './Digital_Marketing_For_Real_Estate_Guntur';
import Digital_Marketing_For_Health_Care from './Digital_Marketing_For_Health_Care';
import Digital_marketing_for_e_commerce from './Digital_marketing_for_e_commerce';
import Digital_marketing_for_startups from './Digital_marketing_for_startups';
import Digital_marketing_for_education from './Digital_marketing_for_education';
import Digital_marketing_for_pharma_companies from './Digital_marketing_for_pharma_companies';
import Digital_marketing_for_manufacturing_Companies from './Digital_marketing_for_manufacturing_Companies';
import Digital_Marketing_for_technology_Companies from './Digital_Marketing_for_technology_Companies';
import Digital_marketing_for_fmcg_companies from './Digital_marketing_for_fmcg_companies';
import ABOUT from './ABOUT';
import Blogs from './Blogs';



function App() {
  return (
  <div>
    
      <BrowserRouter>
      
      <Routes>
        <Route path="/" element={<Home />}/>    
          <Route path="/Home" element={<Home />}/>
          <Route path="/Services" element={<Services />}/>
          <Route path="/Contact" element={<Contact />}/>
          <Route path="/INDUSTRIES" element={<INDUSTRIES />}/>
         
          <Route path="/SEO_Services_in_Guntur" element={<SEO_Services_in_Guntur />}/>
          <Route path="/PPC_Marketing_services_in_guntur" element={<PPC_Marketing_services_in_guntur/>}/>
          <Route path="/Social_Media_Marketing_in_Guntur" element={<Social_Media_Marketing_in_Guntur />}/>
          <Route path="/Content_Analysis_in_Guntur" element={<Content_Analysis_in_Guntur />}/>
          <Route path="/Graphic_Designing_Services_in_Guntur" element={<Graphic_Designing_Services_in_Guntur />}/>
          <Route path="/Web_Design_Development_Services_in_Guntur" element={<Web_Design_Development_Services_in_Guntur />}/>
          <Route path="/Branding_Services_In_Guntur" element={<Branding_Services_In_Guntur />}/>
          <Route path="/E_Commerce_Websites_Development_Services_Guntur" element={<E_Commerce_Websites_Development_Services_Guntur/>}/>
          <Route path="/Logo_Designing_Services_in_Guntur" element={<Logo_Designing_Services_in_Guntur />}/>
          <Route path="/Digital_marketing_for_fmcg_companies" element={<Digital_marketing_for_fmcg_companies />}/>
          <Route path="/Digital_Marketing_For_Real_Estate_Guntur" element={<Digital_Marketing_For_Real_Estate_Guntur />}/>
          <Route path="/Digital_Marketing_For_Health_Care" element={<Digital_Marketing_For_Health_Care />}/>
          <Route path="/Digital_marketing_for_e_commerce" element={<Digital_marketing_for_e_commerce />}/>
          <Route path="/Digital_marketing_for_startups" element={<Digital_marketing_for_startups />}/>
          <Route path="/Digital_marketing_for_education" element={<Digital_marketing_for_education />}/>
          <Route path="/Digital_marketing_for_pharma_companies" element={<Digital_marketing_for_pharma_companies/>}/>
          <Route path="/Digital_marketing_for_manufacturing_Companies" element={<Digital_marketing_for_manufacturing_Companies />}/>
          <Route path="/Digital_Marketing_for_technology_Companies" element={<Digital_Marketing_for_technology_Companies />}/>
          <Route path="/ABOUT" element={<ABOUT />}/>  
          <Route path='/Blogs' element={<Blogs/>}/>
      </Routes>
      
    </BrowserRouter>
    <Footer/>
  </div>
  );
}

export default App;
