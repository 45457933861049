import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { CiMenuBurger } from 'react-icons/ci';
import { TbSettingsCog } from 'react-icons/tb'
import { Carousel } from 'react-bootstrap';


const Social_Media_Marketing_in_Guntur = () => {
    const [menubar, setmenubar] = useState(false)
    const [menubar1, setmenubar1] = useState(false)

    const setmenubarFunction = () => {
        setmenubar(!menubar);
        setmenubar1(!menubar1);
    }

    const [navchange, setNavbar] = useState(false);



    const changebackground = () => {
        if (window.scrollY >= 250) {
            setNavbar(true)
        }
        else {
            setNavbar(false);
        }
    }

    window.addEventListener('scroll', changebackground)
    return (
        <>
            <Carousel className='carouse' indicators={false} controls={false}>
                <Carousel.Item className='carouse1' interval={1000}>
                    <img
                        className="d-block w-100"
                        src="banner.jpg"
                        alt="First slide"
                    />
                    <Carousel.Caption className='caroselCaption'>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Link className='p-2 text-center text' to='/Home' style={{ textDecoration: 'none' }}><h6>HOME</h6></Link>
                            <h6 className='p-2 text' > - </h6>
                            <Link className='p-2' style={{ textDecoration: 'none', color: 'white' }}><h6>SERVICES</h6></Link>
                        </div>
                        <h3 className='display-1'><b>SOCIAL MEDIA MARKETING </b></h3>

                    </Carousel.Caption>
                </Carousel.Item>

            </Carousel>
            {/* <div className='carouse' >
              <div className='banner' >
                  <img src='banner.jpg' alt='' style={{ width: '100%',height:'400px' }}></img>
              </div>
              <div className='container text-center linkItems' style={{ position: 'absolute' }} >
                  <div className='text-center' style={{ display: 'flex'}}>
                      <Link className='p-2 text-center' to='/Home'>Home </Link>
                      <p className='p-2'> - </p>
                      <Link className='p-2'>Services</Link>
                  </div>

              </div>

          </div> */}
            <div className='navs fixed-top shadow-lg  rounded'>
                <nav className={navchange ? 'navbar active' : 'navbar'} >
                    <div class="container-fluid">
                        <div class="navbar-header">
                            <Link className='navbar-brand'><img src='white logo final DART LOGO.png' alt='' style={{ width: '150px', height: '80px', paddingTop: '0px', marginTop: '0px' }}></img></Link>
                        </div>
                        <div className='megaLinks'>
                            <ul className={menubar ? 'nav-links' : 'nav-link'} style={{ listStyleType: 'none' ,marginTop:'15px' }}>
                                <li >
                                    <Link to="/" style={{ textDecoration: 'none', marginLeft: '50px', color: 'white' }}><b>HOME</b></Link>
                                </li>
                                <li>
                                    <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white' }}><b>ABOUT</b></Link>
                                </li>
                                <li>
                                    <Link to="/Services" style={{ textDecoration: 'none', color: 'white' }}><b>SERVICES</b></Link>
                                </li>
                                <li>
                                    <Link to="/Contact" style={{ textDecoration: 'none', color: 'white' }}><b>CONTACT US</b></Link>
                                </li>
                                <li>
                                    <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white' }}><b>INDUSTRIES</b></Link>
                                </li>
                            </ul>
                        </div>

                        <div className='ms-auto'>
                            <CiMenuBurger onClick={setmenubarFunction} style={{ margin: '30px', width: '30px', height: '30px', color: 'white' }} />
                        </div>
                    </div>


                </nav>
                <div className={menubar1 ? 'nav-linked' : 'naved'} >
                    <ul style={{ listStyleType: 'none', alignContent: 'center' }} >
                        <li >
                            <Link to="/" style={{ textDecoration: 'none', color: 'white', textAlign: 'center', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}><b>HOME</b></Link>
                        </li>
                        <li>
                            <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>ABOUT</Link>
                        </li>
                        <li>
                            <Link to="/Services" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>SERVICES</Link>
                        </li>
                        <li>
                            <Link to="/Contact" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>CONTACT US</Link>
                        </li>
                        <li>
                            <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white' }}><b>INDUSTRIES</b></Link>
                        </li>
                    </ul>

                    <div className='container mt-5 p-5' >
                        <div className='row'>
                            <div className='col' style={{ color: 'black' }}>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Phone: 093934 15155</h4>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Email: dartmarketing.in@gmail.com</h4>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '17px' }}>Address:11/2, D.No. 6-11-53/A, 1st Floor, Arundelpet, Guntur, IN - 522002</h4>
                            </div>

                            <div className='container col text-center' style={{ color: 'white', textAlign: 'center' }}>
                                <ul style={{ listStyleType: 'none', display: 'flex' }}>
                                    <li>
                                        <Link to='https://www.facebook.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>facebook</Link></li>
                                    <li><Link to='https://www.instagram.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>youtube</Link></li>
                                    <li><Link style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>twitter</Link></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className='container mt-5'>
                <h1 className='secTitle  text-center' style={{ display: 'flex', justifyContent: 'center', fontSize: '44px', marginTop: '-23px' }}>Social Media Marketing Agency | Reach Your Brand Globally</h1>
                <h4 style={{ color: 'red' }}>Social media marketing agency in Guntur - Proven Experts</h4>
                <p className='container para' style={{ color: 'grey' }}>If you believe, a winning social media presence is all about strategy and creativity, your search should end with us! We combine years of innovative social media experience, with industry-leading tools and technologies to help you grow your brand awareness, increase your website traffic, and promote your products and services online; across platforms using a variety of content formats. When you hire us as your social media marketing company in Guntur, you gain.</p>
                <ul style={{ color: 'grey' }}>
                    <li>Increased social mentions and referrals</li>
                    <li>Improved digital footprints</li>
                    <li>More in-bound traffic</li>
                    <li>Better search engine rankings</li>
                    <li>Higher conversion rates</li>
                    <li>More customer satisfaction and more loyalty</li>
                    <li>Better brand authority</li>
                    <li>More marketplace insights</li>
                    <li>Reputation of a thought leader</li>
                </ul>
                <h4 style={{ color: 'red' }}>Are you listening to your customers?</h4>
                <p className='container para' style={{ color: 'grey' }}>The marketing landscape is fast evolving today. Digital natives with hand-held devices are more demanding, they are fickle-minded and always in search of meaningful, insightful engagements that lead to values and positive relationships. In order to make them actively involved, you should have a very strong social media footprint, solicit feedback about your products, reach your target audience where they frequent, ask relevant open-ended questions, share humanely stories and continuously gather data to improve. If you are not on social media or lack an active presence, you risk being forgotten!</p>
                <h4 style={{ color: 'red' }}>We help you thrive socially</h4>
                <p className='container para' style={{ color: 'grey' }}>Digital Marketing - as one of the fastest growing Social media marketing company in Guntur - seek to redefine how you connect with your audience on social platforms. We create an end-to-end social media marketing strategy identifying the growth platforms, and audience personas - while keeping your branding goals persistently aligned with proposed ideas.</p>
                <h4 style={{ color: 'red' }}>Target, retarget - Be visible everywhere!</h4>
                <p className='container para' style={{ color: 'grey' }}>Social media gives you the power to be seen, be found and create a consistent and meaningful relationship with your audience. Our social media marketing services in Guntur deliver true returns on your social investments by continually harnessing the power of digital media for you, today and tomorrow - according to the need of time and technology.</p>
                <h4 style={{ color: 'red' }}>Intent and types of social media services</h4>
                <p className='container para' style={{ color: 'grey' }}>Social content creation and sharing - Create and share brand-led humanly content across social platforms, including Facebook, Twitter, LinkedIn, Tumbler, Instagram, Snapchat, Pinterest and more. Magnify content reach with blogging, guest posting, influencer marketing</p>
                <h4 style={{ color: 'red' }}>Social networking</h4>
                <p className='container para' style={{ color: 'grey' }}>Content sharing alone does not help. Reach and respond to your audience, make them feel valued, reward their engagement through effective and valuable networking strategies.</p>
                <h4 style={{ color: 'red' }}>Community-building</h4>
                <p className='container para' style={{ color: 'grey' }}>Build a loyal group of returning customers turned fans using Reviews, forums, messaging platforms.</p>
                <h4 style={{ color: 'red' }}>Optimize results</h4>
                <p className='container para' style={{ color: 'grey' }}>Gather key insights based on executed activities and plan to improve to make even better engagement. Digital Marketing as a full-fledged social media marketing services in Guntur leverages on latest tools, technologies to help clients generate more values from social media management, social media advertising and content marketing.</p>
                <h4 style={{ color: 'red' }}>Process</h4>
                <p className='container para mb-5'  style={{ color: 'grey' }}>Our process keep you at the centrestage of all decisions. It's simple, and designed to deliver healthy results. Gathering Requirement and Early analysis - Identifying existing operating platforms, analyzing their engagement score, deciding on new account creation and/or improving existing accounts, understanding key objectives Account creation - Creating new accounts and/or freshen up existing profile, strategy making for community outreach programs, aligning requirement with capacity Content creation - SEO-friendly content creation across various formats for multitude of platforms; without missing the humanly angle Publishing and promotion - Content sharing, ad promotion, ensuring continued social engagement Maintenance - Monitoring for improvement and leveraging on data for better listening</p>     
            </div>

        </>

    )
}

export default Social_Media_Marketing_in_Guntur