import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { CiMenuBurger } from 'react-icons/ci';
import { TbSettingsCog } from 'react-icons/tb'
import { Carousel } from 'react-bootstrap';

const Logo_Designing_Services_in_Guntur = () => {
    const [menubar, setmenubar] = useState(false)
    const [menubar1, setmenubar1] = useState(false)

    const setmenubarFunction = () => {
        setmenubar(!menubar);
        setmenubar1(!menubar1);
    }

    const [navchange, setNavbar] = useState(false);



    const changebackground = () => {
        if (window.scrollY >= 250) {
            setNavbar(true)
        }
        else {
            setNavbar(false);
        }
    }

    window.addEventListener('scroll', changebackground)
    return (
        <>
            <Carousel className='carouse' indicators={false} controls={false}>
                <Carousel.Item className='carouse1' interval={1000}>
                    <img
                        className="d-block w-100"
                        src="banner.jpg"
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Link className='p-2 text-center text' to='/Home' style={{ textDecoration: 'none' }}><h6>HOME</h6></Link>
                            <h6 className='p-2 text' > - </h6>
                            <Link className='p-2' style={{ textDecoration: 'none', color: 'white' }}><h6>SERVICES</h6></Link>
                        </div>
                        <h3 className='display-1'><b> Logo Design</b></h3>

                    </Carousel.Caption>
                </Carousel.Item>

            </Carousel>
            {/* <div className='carouse' >
                <div className='banner' >
                    <img src='banner.jpg' alt='' style={{ width: '100%',height:'400px' }}></img>
                </div>
                <div className='container text-center linkItems' style={{ position: 'absolute' }} >
                    <div className='text-center' style={{ display: 'flex'}}>
                        <Link className='p-2 text-center' to='/Home'>Home </Link>
                        <p className='p-2'> - </p>
                        <Link className='p-2'>Services</Link>
                    </div>
  
                </div>
  
            </div> */}
            <div className='navs fixed-top shadow-lg  rounded'>
                <nav className={navchange ? 'navbar active' : 'navbar'} >
                    <div class="container-fluid">
                        <div class="navbar-header">
                            <Link className='navbar-brand'><img src='white logo final DART LOGO.png' alt='' style={{ width: '150px', height: '80px', paddingTop: '0px', marginTop: '0px' }}></img></Link>
                        </div>
                        <div className='megaLinks'>
                            <ul className={menubar ? 'nav-links' : 'nav-link'} style={{ listStyleType: 'none',marginTop:'15px'  }}>
                                <li >
                                    <Link to="/" style={{ textDecoration: 'none', marginLeft: '50px', color: 'white' }}><b>HOME</b></Link>
                                </li>
                                <li>
                                    <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white' }}><b>ABOUT</b></Link>
                                </li>
                                <li>
                                    <Link to="/Services" style={{ textDecoration: 'none', color: 'white' }}><b>SERVICES</b></Link>
                                </li>
                               
                                <li>
                                    <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white' }}><b>INDUSTRIES</b></Link>
                                </li>
                                <li>
                                    <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white' }}><b>BLOGS</b></Link>
                                </li>
                                <li>
                                    <Link to="/Contact" style={{ textDecoration: 'none', color: 'white' }}><b>CONTACT US</b></Link>
                                </li>
                            </ul>
                        </div>

                        <div className='ms-auto'>
                            <CiMenuBurger onClick={setmenubarFunction} style={{ margin: '30px', width: '30px', height: '30px', color: 'white' }} />
                        </div>
                    </div>


                </nav>
                <div className={menubar1 ? 'nav-linked' : 'naved'} >
                    <ul style={{ listStyleType: 'none', alignContent: 'center' }} >
                        <li >
                            <Link to="/" style={{ textDecoration: 'none', color: 'white', textAlign: 'center', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}><b>HOME</b></Link>
                        </li>
                        <li>
                            <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>ABOUT</Link>
                        </li>
                        <li>
                            <Link to="/Services" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>SERVICES</Link>
                        </li>
                       
                        <li>
                            <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px'  }}>INDUSTRIES</Link>
                        </li>
                        <li>
                            <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>BLOGS</Link>
                        </li>
                        <li>
                            <Link to="/Contact" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>CONTACT US</Link>
                        </li>
                    </ul>

                    <div className='container mt-5 p-5' >
                        <div className='row'>
                            <div className='col' style={{ color: 'black' }}>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Phone: 093934 15155</h4>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Email: dartmarketing.in@gmail.com</h4>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '17px' }}>Address:11/2, D.No. 6-11-53/A, 1st Floor, Arundelpet, Guntur, IN - 522001</h4>
                            </div>

                            <div className='container col text-center' style={{ color: 'white', textAlign: 'center' }}>
                                <ul style={{ listStyleType: 'none', display: 'flex' }}>
                                    <li>
                                        <Link to='https://www.facebook.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>facebook</Link></li>
                                    <li><Link to='https://www.instagram.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>youtube</Link></li>
                                    <li><Link style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>twitter</Link></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className='container mt-5'>
                <h1 className='secTitle  text-center' style={{ display: 'flex', justifyContent: 'center', fontSize: '44px', marginTop: '-23px' }}>Logo Design Services</h1>
                <h4 className='mb-4' style={{ color: 'red' }}>Corporate Logo Design Services</h4>
                <p className='container para mb-4' style={{ color: 'grey' }}>We are a logo design company, helping you promote and take your brand to new heights. Digital Marketing Consultants provides creative logo designing services in Guntur.
                    Type 'Logo Design services' in Google, and chances are you might confused as to who is better, who is affordable, and how is not lying? We understand how crucial a logo is to represent your professional identity and therefore, we would first listen to you, before telling about ourselves.</p>
                <p className='container para mb-4' style={{ color: 'grey' }}>Digital Marketing prides in being your ethical logo designing partner. We do not spam you with unintended promotional materials to get attention and we do not want you to listen to our success stories until we make yours, and we keep it short and simple!</p>
                <p className='container para mb-4' style={{ color: 'grey' }}>Approach is what makes it unique.</p>
                <p className='container para mb-4' style={{ color: 'grey' }}>Our logo design services in Guntur do not begin with the designer. It begins with our marketers. We gather your requirements, study your market, and explore the scope of creative your audience can handle.</p>
                <p className='container para mb-4' style={{ color: 'grey' }}>The next stage starts with the designer ready with the roadmap. After thorough analysis and review, we get the logo done - which you should like.</p>
            </div>
        </>

    )
}

export default Logo_Designing_Services_in_Guntur