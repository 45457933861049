
import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import Container from 'react-bootstrap/Container';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { CiMenuBurger } from 'react-icons/ci';
import AOS from "aos";
import "aos/dist/aos.css";


const Header = () => {

  useEffect(() => {
    AOS.init();

  }, []);

  const [menubar, setmenubar] = useState(false)
  const [menubar1, setmenubar1] = useState(false)

  const setmenubarFunction = () => {
    setmenubar(!menubar);
    setmenubar1(!menubar1);
  }


  const [navchange, setNavbar] = useState(false);




  const changebackground = () => {
    if (window.scrollY >= 250) {
      setNavbar(true)
    }
    else {
      setNavbar(false);
    }
  }

  window.addEventListener('scroll', changebackground)
  return (
    <>
      <Carousel className='carouse' indicators={false} >
        <Carousel.Item className='item'>
          <img
            className="d-block w-100"
            src="Add a subheading (2).png"
            alt="First slide"
            style={{ height: '80%' }}
          />
          {/* <Carousel.Caption>
            <h3  data-aos="fade-up" data-aos-duration="2000">First slide label</h3>
            <p data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="2000">Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item className='item'>
          <img
            className="d-block w-100"
            src="Add a subheading (3).png"
            alt="Second slide"
            style={{ height: '80%' }}
          />
          {/* <Carousel.Caption>
            <h3  data-aos="fade-up" data-aos-duration="2000">First slide label</h3>
            <p data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="2000">Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item className='item'>
          <img
            className="d-block w-100"
            src="Add a subheading.png"
            alt="Third slide"
            style={{ height: '80%' }}
          />
          {/* <Carousel.Caption>
            <h3  data-aos="fade-up" data-aos-duration="2000">First slide label</h3>
            <p data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="2000">Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>



      <div className=' navs fixed-top shadow-lg  rounded'>
        <nav className={navchange ? 'navbar active' : 'navbar'} >
          <div class="container-fluid">
            <div class="navbar-header">
              <Link className='navbar-brand'><img src='white logo final DART LOGO.png' alt='' ></img></Link>
            </div>
            <div className='megaLinks '>
              <ul className={menubar ? 'nav-links' : 'nav-link'} style={{ listStyleType: 'none',marginTop:'15px'}}>
                <li>
                  <Link to="/Home" style={{ textDecoration: 'none', marginLeft: '50px', color: 'white' }}><b>HOME</b></Link>
                </li>
                <li>
                  <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white' }}><b>ABOUT</b></Link>
                </li>
                <li>
                  <Link to="/Services" style={{ textDecoration: 'none', color: 'white' }}><b>SERVICES</b></Link>
                </li>

                <li>
                  <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white' }}><b>INDUSTRIES</b></Link>
                </li>
                <li>
                  <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white' }}><b>BLOGS</b></Link>
                </li>
                <li>
                  <Link to="/Contact" style={{ textDecoration: 'none', color: 'white' }}><b>CONTACT US</b></Link>
                </li>
              </ul>
            </div>

            <div className='ms-auto'>
              <CiMenuBurger onClick={setmenubarFunction} style={{ margin: '30px', width: '30px', height: '30px', color: 'white' }} />
            </div>
          </div>



        </nav>
        <div className={menubar1 ? 'nav-linked' : 'naved'} >
          <ul style={{ listStyleType: 'none', alignContent: 'center' }} >
            <li >
              <Link to="/Home" style={{ textDecoration: 'none', color: 'white', textAlign: 'center', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}><b>HOME</b></Link>
            </li>
            <li>
              <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>ABOUT</Link>
            </li>
            <li>
              <Link to="/Services" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>SERVICES</Link>
            </li>

            <li>
              <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}><b>INDUSTRIES</b></Link>
            </li>
            <li>
              <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}><b>BLOGS</b></Link>
            </li>
            <li>
              <Link to="/Contact" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>CONTACT US</Link>
            </li>
          </ul>


          <div className='container mt-5 p-5' >
            <div className='row'>
              <div className='col' style={{ color: 'black' }}>
                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Phone: 093934 15155</h4>
                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Email: dartmarketing.in@gmail.com</h4>
                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '17px' }}>Address:11/2, D.No. 6-11-53/A, 1st Floor, Arundelpet, Guntur, IN - 522002</h4>
              </div>

              <div className='container col text-center' style={{ color: 'white', textAlign: 'center' }}>
                <ul style={{ listStyleType: 'none', display: 'flex' }}>
                  <li>
                    <Link to='https://www.facebook.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>facebook</Link></li>
                  <li><Link to='https://www.instagram.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>youtube</Link></li>
                  <li><Link style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>twitter</Link></li>

                </ul>
              </div>
            </div>
          </div>
        </div>


      </div>

      {/* <div>

        <div className='navs fixed-top shadow-lg  rounded'>
          <nav className={navchange ? 'navbar active' : 'navbar'} >
            <div class="container-fluid">
              <div class="navbar-header">
                <Link className='navbar-brand'><img src='white logo final DART LOGO.png' alt='' style={{ width: '150px', height: '80px', paddingTop: '0px', marginTop: '0px' }}></img></Link>
              </div>
              <div className='megaLinks'>
                <ul className={menubar ? 'nav-links' : 'nav-link'} style={{ listStyleType: 'none' }}>
                  <li >
                    <Link to="/Home" style={{ textDecoration: 'none', marginLeft: '50px', color: 'white' }}><b>HOME</b></Link>
                  </li>
                  <li>
                    <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white' }}><b>ABOUT</b></Link>
                  </li>
                  <li>
                    <Link to="/Services" style={{ textDecoration: 'none', color: 'white' }}><b>SERVICES</b></Link>
                  </li>

                  <li>
                    <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white' }}><b>INDUSTRIES</b></Link>
                  </li>
                  <li>
                    <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white' }}><b>BLOGS</b></Link>
                  </li>
                  <li>
                    <Link to="/Contact" style={{ textDecoration: 'none', color: 'white' }}><b>CONTACT US</b></Link>
                  </li>
                </ul>
              </div>

              <div className='ms-auto'>
                <CiMenuBurger onClick={setmenubarFunction} style={{ margin: '30px', width: '30px', height: '30px', color: 'white' }} />
              </div>
            </div>



          </nav>
          <div className={menubar1 ? 'nav-linked' : 'naved'} >
            <ul style={{ listStyleType: 'none', alignContent: 'center' }} >
              <li >
                <Link to="/Home" style={{ textDecoration: 'none', color: 'white', textAlign: 'center', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}><b>HOME</b></Link>
              </li>
              <li>
                <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>ABOUT</Link>
              </li>
              <li>
                <Link to="/Services" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>SERVICES</Link>
              </li>

              <li>
                <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}><b>INDUSTRIES</b></Link>
              </li>
              <li>
                <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>BLOGS</Link>
              </li>
              <li>
                <Link to="/Contact" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>CONTACT US</Link>
              </li>
            </ul>

            <div className='container mt-5 p-5' >
              <div className='row'>
                <div className='col' style={{ color: 'black' }}>
                  <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Phone: 093934 15155</h4>
                  <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Email: dartmarketing.in@gmail.com</h4>
                  <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '17px' }}>Address:11/2, D.No. 6-11-53/A, 1st Floor, Arundelpet, Guntur, IN - 522002</h4>
                </div>

                <div className='container col text-center' style={{ color: 'white', textAlign: 'center' }}>
                  <ul style={{ listStyleType: 'none', display: 'flex' }}>
                    <li>
                      <Link to='https://www.facebook.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>facebook</Link></li>
                    <li><Link to='https://www.instagram.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>youtube</Link></li>
                    <li><Link style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>twitter</Link></li>

                  </ul>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div> */}
    </>
  )

}

export default Header