import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { CiMenuBurger } from 'react-icons/ci';
import { TbSettingsCog } from 'react-icons/tb'
import { Carousel } from 'react-bootstrap';


const Branding_Services_In_Guntur = () => {
    const [menubar, setmenubar] = useState(false)
    const [menubar1, setmenubar1] = useState(false)

    const setmenubarFunction = () => {
        setmenubar(!menubar);
        setmenubar1(!menubar1);
    }

    const [navchange, setNavbar] = useState(false);



    const changebackground = () => {
        if (window.scrollY >= 250) {
            setNavbar(true)
        }
        else {
            setNavbar(false);
        }
    }

    window.addEventListener('scroll', changebackground)
    return (
        <>
            <Carousel className='carouse' indicators={false} controls={false}>
                <Carousel.Item className='carouse1' interval={1000}>
                    <img
                        className="d-block w-100"
                        src="banner.jpg"
                        alt="First slide"
                    />
                    <Carousel.Caption className='caroselCaption'>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Link className='p-2 text-center text' to='/Home' style={{ textDecoration: 'none' }}><h6>HOME</h6></Link>
                            <h6 className='p-2 text' > - </h6>
                            <Link className='p-2' style={{ textDecoration: 'none', color: 'white' }}><h6>SERVICES</h6></Link>
                        </div>
                        <h3 className='display-1'><b>Branding Services</b></h3>

                    </Carousel.Caption>
                </Carousel.Item>

            </Carousel>
            {/* <div className='carouse' >
                <div className='banner' >
                    <img src='banner.jpg' alt='' style={{ width: '100%',height:'400px' }}></img>
                </div>
                <div className='container text-center linkItems' style={{ position: 'absolute' }} >
                    <div className='text-center' style={{ display: 'flex'}}>
                        <Link className='p-2 text-center' to='/Home'>Home </Link>
                        <p className='p-2'> - </p>
                        <Link className='p-2'>Services</Link>
                    </div>

                </div>

            </div> */}
            <div className='navs fixed-top shadow-lg  rounded'>
                <nav className={navchange ? 'navbar active' : 'navbar'} >
                    <div class="container-fluid">
                        <div class="navbar-header">
                            <Link className='navbar-brand'><img src='white logo final DART LOGO.png' alt='' style={{ width: '150px', height: '80px', paddingTop: '0px', marginTop: '0px' }}></img></Link>
                        </div>
                        <div className='megaLinks'>
                            <ul className={menubar ? 'nav-links' : 'nav-link'} style={{ listStyleType: 'none' ,marginTop:'15px'}}>
                                <li >
                                    <Link to="/" style={{ textDecoration: 'none', marginLeft: '50px', color: 'white' }}><b>HOME</b></Link>
                                </li>
                                <li>
                                    <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white' }}><b>ABOUT</b></Link>
                                </li>
                                <li>
                                    <Link to="/Services" style={{ textDecoration: 'none', color: 'white' }}><b>SERVICES</b></Link>
                                </li>
                                
                                <li>
                                    <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white' }}><b>INDUSTRIES</b></Link>
                                </li>

                                <li>
                                    <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white' }}><b>BLOGS</b></Link>
                                </li>
                                <li>
                                    <Link to="/Contact" style={{ textDecoration: 'none', color: 'white' }}><b>CONTACT US</b></Link>
                                </li>
                            </ul>
                        </div>

                        <div className='ms-auto'>
                            <CiMenuBurger onClick={setmenubarFunction} style={{ margin: '30px', width: '30px', height: '30px', color: 'white' }} />
                        </div>
                    </div>


                </nav>
                <div className={menubar1 ? 'nav-linked' : 'naved'} >
                    <ul style={{ listStyleType: 'none', alignContent: 'center' }} >
                        <li >
                            <Link to="/" style={{ textDecoration: 'none', color: 'white', textAlign: 'center', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}><b>HOME</b></Link>
                        </li>
                        <li>
                            <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>ABOUT</Link>
                        </li>
                        <li>
                            <Link to="/Services" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>SERVICES</Link>
                        </li>
                      
                        <li>
                            <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px'  }}><b>INDUSTRIES</b></Link>
                        </li>
                        <li>
                            <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>BLOGS</Link>
                        </li>
                        <li>
                            <Link to="/Contact" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>CONTACT US</Link>
                        </li>
                    </ul>

                    <div className='container mt-5 p-5' >
                        <div className='row'>
                            <div className='col' style={{ color: 'black' }}>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Phone: 093934 15155</h4>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Email: dartmarketing.in@gmail.com</h4>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '17px' }}>Address:11/2, D.No. 6-11-53/A, 1st Floor, Arundelpet, Guntur, IN - 522002</h4>
                            </div>

                            <div className='container col text-center' style={{ color: 'white', textAlign: 'center' }}>
                                <ul style={{ listStyleType: 'none', display: 'flex' }}>
                                    <li>
                                        <Link to='https://www.facebook.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>facebook</Link></li>
                                    <li><Link to='https://www.instagram.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>youtube</Link></li>
                                    <li><Link style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>twitter</Link></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className='container mt-5 '>
                <h1 className='secTitle  text-center' style={{ display: 'flex', justifyContent: 'center', fontSize: '44px', marginTop: '-23px' }}>Branding</h1>
                <h4 className='mb-4' style={{ color: 'red' }}>Branding,Advertising and Creative agency in Guntur</h4>
                <p className='container para mb-4' style={{ color: 'grey' }}>Your customers make an opinion about your brand within the first few seconds of interacting with you. We make sure it is good.</p>
                <p className='container para mb-4' style={{ color: 'grey' }}>Digital Marketing - a branding services company in Guntur, make all the differences your company desire. We assist companies create an impressive brand identity across platforms. We leverage on our industry expertise, technology accelerators, market positioning and a variety of agile and flexible engagement practices to help you to thrive in the competitive marketplace. With us your branding partner, you develop a memorable positive brand identity in the mind of your customer, benefit from a trusted status and create key differentiators from your competitors.</p>
                <h4 className='mb-4' style={{ color: 'red' }}>You are not a brand until you make a difference</h4>                
                <p className='container para mb-4' style={{ color: 'grey' }}>In today's evolving marketplace, you can't entirely rely on common branding practices. Designing a logo, having a corporate website and getting business card printed is not the end of branding activities. You should explore creative ideas that are relevant for digital natives, continuously cater to the changing demands of a demanding audience base. Most importantly, you need to create memorable experiences that your customers identify with.</p>
                <h4 className='mb-4' style={{ color: 'red' }}>Not every company is a brand. Those who are, are really good!</h4>
                <p className='container para mb-4' style={{ color: 'grey' }}>Becoming brand is a continuous process of innovation. A very hard target very few can actually achieve. As a business, you need to be proactive, agile, collaborative and responsive. You should embrace change. You should create positive difference in the life of your customers.</p>
                <h4 className='mb-4' style={{ color: 'red' }}>Digital Marketing- Your single-source provider of all branding solutions</h4>
                <p className='container para mb-4' style={{ color: 'grey' }}>Digital Marketing is your complete corporate branding solutions partner. We help you stand out in same of sameness, establish trust, and drive more targeted outcomes to your business, such as increasing sales. We provide a comprehensive range of branding solutions across your business journey. Whether you are a startup, a small and medium-sized business or an old organization; if you have not yet created impact a brand should create, you can trust us!</p>
                <h4 className='mb-4' style={{ color: 'red' }}>Our Branding Solutions</h4>

                <ul className='mb-4' style={{ color: 'grey' }}>
                    <li>Business name selection</li>
                    <li>Color psychology, font, tagline creation and development</li>
                    <li>Brand style sheet development</li>
                    <li>Brand marketing and promotion</li>
                    <li>Marketing collaterals</li>
                    <li>Audience research and customer persona development</li>
                    <li>Logo design</li>
                    <li>Brochure and stationery design</li>
                    <li>Vision and mission statements</li>
                    <li>Website design and development</li>
                    <li>Emailer</li>
                    <li>Packaging and product design</li>
                    <li>Internal communication</li>
                </ul>
            </div>
        </>

    )
}

export default Branding_Services_In_Guntur