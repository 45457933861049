import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { CiMenuBurger } from 'react-icons/ci';
import { TbSettingsCog } from 'react-icons/tb'
import { Carousel } from 'react-bootstrap';


const Content_Analysis_in_Guntur = () => {
    const [menubar, setmenubar] = useState(false)
    const [menubar1, setmenubar1] = useState(false)

    const setmenubarFunction = () => {
        setmenubar(!menubar);
        setmenubar1(!menubar1);
    }

    const [navchange, setNavbar] = useState(false);



    const changebackground = () => {
        if (window.scrollY >= 250) {
            setNavbar(true)
        }
        else {
            setNavbar(false);
        }
    }

    window.addEventListener('scroll', changebackground)
    return (
        <>
            <Carousel className='carouse' indicators={false} controls={false}>
                <Carousel.Item className='carouse1' interval={1000}>
                    <img
                        className="d-block w-100"
                        src="banner.jpg"
                        alt="First slide"
                    />
                    <Carousel.Caption className='caroselCaption'>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Link className='p-2 text-center text' to='/Home' style={{ textDecoration: 'none' }}><h6>HOME</h6></Link>
                            <h6 className='p-2 text' > - </h6>
                            <Link className='p-2' style={{ textDecoration: 'none', color: 'white' }}><h6>SERICES</h6></Link>
                        </div>
                        <h3 className='display-1'><b>CONTENT ANALYSIS</b></h3>

                    </Carousel.Caption>
                </Carousel.Item>

            </Carousel>
            {/* <div className='carouse' >
              <div className='banner' >
                  <img src='banner.jpg' alt='' style={{ width: '100%',height:'400px' }}></img>
              </div>
              <div className='container text-center linkItems' style={{ position: 'absolute' }} >
                  <div className='text-center' style={{ display: 'flex'}}>
                      <Link className='p-2 text-center' to='/Home'>Home </Link>
                      <p className='p-2'> - </p>
                      <Link className='p-2'>Services</Link>
                  </div>

              </div>

          </div> */}
            <div className='navs fixed-top shadow-lg  rounded'>
                <nav className={navchange ? 'navbar active' : 'navbar'} >
                    <div class="container-fluid">
                        <div class="navbar-header">
                            <Link className='navbar-brand'><img src='white logo final DART LOGO.png' alt='' style={{ width: '150px', height: '80px', paddingTop: '0px', marginTop: '0px' }}></img></Link>
                        </div>
                        <div className='megaLinks'>
                            <ul className={menubar ? 'nav-links' : 'nav-link'} style={{ listStyleType: 'none',marginTop:'15px' }}>
                                <li >
                                    <Link to="/" style={{ textDecoration: 'none', marginLeft: '50px', color: 'white' }}><b>HOME</b></Link>
                                </li>
                                <li>
                                    <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white' }}><b>ABOUT</b></Link>
                                </li>
                                <li>
                                    <Link to="/Services" style={{ textDecoration: 'none', color: 'white' }}><b>SERVICES</b></Link>
                                </li>
                                
                                <li>
                                    <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white' }}><b>INDUSTRIES</b></Link>
                                </li>
                                <li>
                                    <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white' }}><b>Blogs</b></Link>
                                </li>
                                <li>
                                    <Link to="/Contact" style={{ textDecoration: 'none', color: 'white' }}><b>CONTACT US</b></Link>
                                </li>
                            </ul>
                        </div>

                        <div className='ms-auto'>
                            <CiMenuBurger onClick={setmenubarFunction} style={{ margin: '30px', width: '30px', height: '30px', color: 'white' }} />
                        </div>
                    </div>


                </nav>
                <div className={menubar1 ? 'nav-linked' : 'naved'} >
                    <ul style={{ listStyleType: 'none', alignContent: 'center' }} >
                        <li >
                            <Link to="/" style={{ textDecoration: 'none', color: 'white', textAlign: 'center', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}><b>HOME</b></Link>
                        </li>
                        <li>
                            <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>ABOUT</Link>
                        </li>
                        <li>
                            <Link to="/Services" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>SERVICES</Link>
                        </li>
                        
                        <li>
                            <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white' , fontWeight: 'bold', fontSize: '20px' }}><b>INDUSTRIES</b></Link>
                        </li>
                        <li>
                            <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>BLOGS</Link>
                        </li>
                        <li>
                            <Link to="/Contact" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>CONTACT US</Link>
                        </li>
                    </ul>

                    <div className='container mt-5 p-5' >
                        <div className='row'>
                            <div className='col' style={{ color: 'black' }}>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Phone: 093934 15155</h4>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Email: dartmarketing.in@gmail.com</h4>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '17px' }}>Address:11/2, D.No. 6-11-53/A, 1st Floor, Arundelpet, Guntur, IN - 522002</h4>
                            </div>

                            <div className='container col text-center' style={{ color: 'white', textAlign: 'center' }}>
                                <ul style={{ listStyleType: 'none', display: 'flex' }}>
                                    <li>
                                        <Link to='https://www.facebook.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>facebook</Link></li>
                                    <li><Link to='https://www.instagram.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>youtube</Link></li>
                                    <li><Link style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>twitter</Link></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='container mt-5'>
                <h1 className='secTitle  text-center' style={{ display: 'flex', justifyContent: 'center', fontSize: '44px', marginTop: '-23px' }}>Content Marketing Services for Traffic,Rankings & Sales</h1>
                <h4 style={{ color: 'red' }}>Content That is Crafted to Convert</h4>
                <p className='container para' style={{ color: 'grey' }}>Identify a clearly defined convertible audience, share content that drives multi channel engagement, and build continued brand loyalty and trust. Digital Marketing content marketing works on a committed goal to expand your website’s digital footprint across platforms.</p>
                <h4 style={{ color: 'red' }}>Open new channels of communication using a holistic, brand-led content marketing strategy</h4>
                <p className='container para' style={{ color: 'grey' }}>With Digital Marketing as our growth partner, you can benefit from continually growing customer engagement. Our best content marketing services in Guntur helps you achieve a number of objectives, including -</p>
                <ul style={{ color: 'grey' }}>
                    <li>Increased brand visibility</li>
                    <li>Develop lasting healthy relationship with audience</li>
                    <li>Accelerate brand awareness and your social recognition</li>
                    <li>Build brand authority and credibility</li>
                    <li>Build brand authority and credibility</li>
                    <li>Become a thought leader in the industry</li>
                    <li>Drive targeted qualified traffic to your website</li>
                    <li>Create new opportunities of healthy communication through meaningful social interactions</li>
                </ul>
                <p className='container para' style={{ color: 'grey' }}>Simply out, with Digital Marketing as your trusted content marketing agency in Guntur, you can drive conversion, build favourable customer engagements that leads to profitable results.</p>
                <h4 style={{ color: 'red' }}>Achieve measurable results with purpose-driven content</h4>
                <p className='container para' style={{ color: 'grey' }}>Everyone is talking about content marketing, but most fail to understand how it works and how to execute it for more profitable results. As a one of the fastest-growing content marketing company in Guntur, India; we first address the challenges a content marketing campaign might face, and then design the roadmap based on purpose that is sure to bring positive outcomes. A good content marketing is done, when -</p>
                You know the goals of it
                <ul style={{ color: 'grey' }}>
                    <li>You concentrate on a narrow target audience and understand their challenges</li>
                    <li>Write with clarity</li>
                    <li>Share content wherever it is necessary, measure its effectiveness and improve</li>

                </ul>
                <p className='container para' style={{ color: 'grey' }}>Digital Marketing's content marketing services have helped brands establish as a go-to source for credible source of information , create unique content formats to share across social platforms, explore new A/B testing ideas and measure genuine success using latest tools</p>

                <h4 style={{ color: 'red' }}>Types of content marketing services at Digital marketing</h4>
                <ul style={{ color: 'grey' }}>
                    <li>ABOUT</li>
                    <li>Infographic</li>
                    <li>SEO Content writing</li>
                    <li>Press Releases</li>
                    <li>eBook</li>
                    <li>VABOUT</li>
                    <li>Case Studies</li>
                </ul>
                <p className='container para' style={{ color: 'grey' }}>Content Audit - Get clearly-focused, action-led content that convert readers into repeat buyers Content strategy - Prepare meaningful content strategy that creates consistent values across all content formats Content creation - Write meaningful content that address your customers' challenges Analytics and Optimization - Analyse your performance and continuously seek to improve to make better use of your efforts</p>
                <h4 style={{ color: 'red' }}>Process</h4>
               <p className='container para' style={{ color: 'grey' }}>Research - Before initiating any creative activity, we gather proper requirements, research based on the facts and prepare a guided roadmap Create - Once we know who your audiences are, and the type of content that is perfectly suited for establishing a far-sighted camping, we begin the creative process - that involves drafting and finalizing a variety of content formats. Sharing - We share the prepared content across social media platforms for increased recognition, driving engagement that is more social. Measure and improve - We test, and try - and improvise based on the data. Monthly reports cover traffic and sales growth info.</p>

            </div>
        </>

    )
}

export default Content_Analysis_in_Guntur