import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { CiMenuBurger } from 'react-icons/ci';
import { TbSettingsCog } from 'react-icons/tb'
import { Carousel } from 'react-bootstrap';


const PPC_Marketing_services_in_guntur = () => {
    const [menubar, setmenubar] = useState(false)
    const [menubar1, setmenubar1] = useState(false)

    const setmenubarFunction = () => {
        setmenubar(!menubar);
        setmenubar1(!menubar1);
    }

    const [navchange, setNavbar] = useState(false);



    const changebackground = () => {
        if (window.scrollY >= 250) {
            setNavbar(true)
        }
        else {
            setNavbar(false);
        }
    }

    window.addEventListener('scroll', changebackground)
    return (
        <>
            <Carousel className='carouse' indicators={false} controls={false}>
                <Carousel.Item className='carouse1' interval={1000}>
                    <img
                        className="d-block w-100"
                        src="banner.jpg"
                        alt="First slide"
                    />
                    <Carousel.Caption className='caroselCaption'>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Link className='p-2 text-center text' to='/Home' style={{ textDecoration: 'none' }}><h6>HOME</h6></Link>
                            <h6 className='p-2 text' > - </h6>
                            <Link className='p-2' style={{ textDecoration: 'none', color: 'white' }}><h6>SERVICES</h6></Link>
                        </div>
                        <h3 className='display-1'><b>PPC MARKETING</b></h3>

                    </Carousel.Caption>
                </Carousel.Item>

            </Carousel>
            {/* <div className='carouse' >
              <div className='banner' >
                  <img src='banner.jpg' alt='' style={{ width: '100%',height:'400px' }}></img>
              </div>
              <div className='container text-center linkItems' style={{ position: 'absolute' }} >
                  <div className='text-center' style={{ display: 'flex'}}>
                      <Link className='p-2 text-center' to='/Home'>Home </Link>
                      <p className='p-2'> - </p>
                      <Link className='p-2'>Services</Link>
                  </div>

              </div>

          </div> */}
            <div className='navs fixed-top shadow-lg  rounded'>
                <nav className={navchange ? 'navbar active' : 'navbar'} >
                    <div class="container-fluid">
                        <div class="navbar-header">
                            <Link className='navbar-brand'><img src='white logo final DART LOGO.png' alt='' style={{ width: '150px', height: '80px', paddingTop: '0px', marginTop: '0px' }}></img></Link>
                        </div>
                        <div className='megaLinks'>
                            <ul className={menubar ? 'nav-links' : 'nav-link'} style={{ listStyleType: 'none',marginTop:'15px' }}>
                                <li >
                                    <Link to="/" style={{ textDecoration: 'none', marginLeft: '50px', color: 'white' }}><b>HOME</b></Link>
                                </li>
                                <li>
                                    <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white' }}><b>ABOUT</b></Link>
                                </li>
                                <li>
                                    <Link to="/Services" style={{ textDecoration: 'none', color: 'white' }}><b>SERVICES</b></Link>
                                </li>
                               
                                <li>
                                    <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white' }}><b>INDUSTRIES</b></Link>
                                </li>
                                <li>
                                    <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white' }}><b>BLOGS</b></Link>
                                </li>
                                <li>
                                    <Link to="/Contact" style={{ textDecoration: 'none', color: 'white' }}><b>CONTACT US</b></Link>
                                </li>
                            </ul>
                        </div>

                        <div className='ms-auto'>
                            <CiMenuBurger onClick={setmenubarFunction} style={{ margin: '30px', width: '30px', height: '30px', color: 'white' }} />
                        </div>
                    </div>


                </nav>
                <div className={menubar1 ? 'nav-linked' : 'naved'} >
                    <ul style={{ listStyleType: 'none', alignContent: 'center' }} >
                        <li >
                            <Link to="/" style={{ textDecoration: 'none', color: 'white', textAlign: 'center', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}><b>HOME</b></Link>
                        </li>
                        <li>
                            <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>ABOUT</Link>
                        </li>
                        <li>
                            <Link to="/Services" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>SERVICES</Link>
                        </li>
                       
                        <li>
                            <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}><b>INDUSTRIES</b></Link>
                        </li>
                        <li>
                            <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>BLOGS</Link>
                        </li>
                        <li>
                            <Link to="/Contact" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>CONTACT US</Link>
                        </li>
                    </ul>

                    <div className='container mt-5 p-5' >
                        <div className='row'>
                            <div className='col' style={{ color: 'black' }}>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Phone: 093934 15155</h4>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Email: dartmarketing.in@gmail.com</h4>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '17px' }}>Address:11/2, D.No. 6-11-53/A, 1st Floor, Arundelpet, Guntur, IN - 522002</h4>
                            </div>

                            <div className='container col text-center' style={{ color: 'white', textAlign: 'center' }}>
                                <ul style={{ listStyleType: 'none', display: 'flex' }}>
                                    <li>
                                        <Link to='https://www.facebook.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>facebook</Link></li>
                                    <li><Link to='https://www.instagram.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>youtube</Link></li>
                                    <li><Link style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>twitter</Link></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='container mt-5'>
                <h1 className='secTitle  text-center' style={{ display: 'flex', justifyContent: 'center', fontSize: '44px', marginTop: '-23px' }}>PPC Marketing Services In Guntur</h1>
                <h4 style={{ color: 'red' }}>Pay Per Click: What is it?</h4>
                <p className='container para' style={{ color: 'grey' }}>Pay Per Click (PPC) or search engine marketing (SEM) are other names for PPC. You only get paid with PPC advertising when a user clicks on your ad. Our group of qualified PPC specialists create and carry out campaigns that are tailored to your buyer persona. We can choose as many keywords as we want to use in PPC advertising to display our advertisements. In most cases, PPC results can be seen within a few days.</p>
                <h4 style={{ color: 'red' }}>Advantages of Pay Per Click?</h4>
                <ul style={{ color: 'grey' }}>
                    <li>Top Positions: Improves Brand Visibility</li>
                    <li>Targeted Audience: Reach Specific Audience as per business.</li>
                    <li>Fast Results: Get your ads live in less than 1 Hour.</li>
                    <li>Pay for Results: We pay for visitors generated to our Site.</li>
                    <li>High Visibility: Search ads can increase brand awareness by 80%</li>
                    <li>More Qualified Buyers: 20% - 25% People Click on Paid Ads.</li>
                    <li>Mobile Advertising: 70% of mobile searchers call a business directly from Google Search</li>
                    <li>Double ROI: Average $2 in income for every $1 they spend in AdWords .......................................................</li>

                </ul>

                <h4 style={{ color: 'red' }}>Websites are important for businesses.</h4>
                <p className='container para' style={{ color: 'grey' }}>Consider your website to be the customer's showroom. It serves as the entrance to your company. The customer would in fact be interacting with your company for the first time at this point. An excellent user experience would leave a lasting impression. Finding leads would be simpler if your website had a good user experience and was well-designed</p>
                <p className='container para' style={{ color: 'grey' }}>If you currently have a website, ITinfo Digital can make it more user-friendly and outstandingly designed. By generating leads, we ensure good revenue. With 200+ projects under our belts, we have a thorough understanding of the various genres and can guarantee high-quality website designing services for your business.</p>
                <p className='container para' style={{ color: 'grey' }}>There are undoubtedly significant factors that would push any company to create a website. Nowadays, everyone uses the internet, and 70% of people would opt to do business with you after seeing your website. In addition, 85% of website visitors are initially drawn in by the site's layout.</p>

                <h4 style={{ color: 'red' }}>The following characteristics belong on a website that has been correctly designed:</h4>

                <ul style={{ color: 'grey' }}>
                    <li>Mobile-friendly & user-friendly</li>
                    <li>Optimized SEO</li>
                    <li>Great themes, beautiful design</li>
                    <li>Quick & Secure</li>
                </ul>
                <h4 style={{ color: 'red' }}>The top web design company in Guntur in Digital Marketing.</h4>

                <ul style={{ color: 'grey' }} className='mb-5'>
                    <li>We have more than 15 years of experience in the digital sector.</li>
                    <li>Our highly skilled WordPress specialist team creates unique, search engine friendly websites.</li>
                    <li>Working Knowledge of Over 100 Themes</li>

                </ul>
             
            </div>

        </>

    )
}

export default PPC_Marketing_services_in_guntur