import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { BsFillCollectionPlayFill } from 'react-icons/bs'
import { AiOutlineTwitter } from 'react-icons/ai';
import { BsInstagram } from 'react-icons/bs'
import { BsFacebook } from 'react-icons/bs'
import CountUp from 'react-countup'

import ScrollTrigger from 'react-scroll-trigger';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import Footer from './Footer';
import Header from './Header';
import AOS from "aos";
import "aos/dist/aos.css";


const Home = () => {
    const [counterstart, setcounterstart] = useState(false);


    return (
        <>
            <Header />


            <div className='container-fluid' style={{ backgroundColor: 'black' }} >
                <h6 className="SubTitle text-center" style={{ color: 'red', paddingTop: '50px' }}>SERVICES WE ARE OFFERING</h6>
                <h1 className='secTitle text-center' style={{ display: 'flex', color: 'white', justifyContent: 'center', fontSize: '44px', fontWeight: '400px' }}>WE DESIGN DIGITAL PRODUCTS THAT</h1>
                <h1 className='secTitle container text-center' style={{ display: 'flex', color: 'white', justifyContent: 'center', fontSize: '44px', marginTop: '-23px' }}> HELP GROW BUSINESSES</h1>
                <p className='container text-center para' style={{ color: 'grey' }}>We are committed to providing our customers with exceptional service
                    while offering our employees the best training.</p>

                {/* /* first card   */}

                <div className='container my-5 ' >
                    <div className='row' style={{ paddingBottom: '80px' }}>
                        <div className="col">
                            <div className='flippable-card-container mb-3 mt-3'>
                                <div className='cards'>
                                    <div className='card-back' >
                                        <img alt="" src='development.png'></img>
                                        <p style={{ color: 'grey', fontSize: '15px', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>Website Development</p>
                                    </div>
                                    <div className='card-front'>

                                        <img alt="" className='center' src='development.png' ></img>
                                        <p style={{ color: 'white', fontSize: '15px', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>Website development</p>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* /* second card   */}

                        <div class="col" >
                            <div className='flippable-card-container mb-3 mt-3'>
                                <div className='cards'>
                                    <div className='card-back' >
                                        <img alt="" src='GraphicDesign.png'></img>
                                        <p style={{ color: 'grey', fontSize: '15px', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>Graphic Design</p>
                                    </div>
                                    <div className='card-front'>
                                        <img alt="" className='center' src='GraphicDesign.png' ></img>
                                        <p style={{ color: 'white', fontSize: '15px', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>Graphic Design</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* /* third card   */}

                        <div class="col">
                            <div className='flippable-card-container mb-3 mt-3'>
                                <div className='cards'>
                                    <div className='card-back' >
                                        <img alt="" src='DigitalMarketingIcon.png'></img>
                                        <p style={{ color: 'grey', fontSize: '15px', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>Digital marketing</p>
                                    </div>
                                    <div className='card-front'>
                                        <img alt="" className='center' src='DigitalMarketingIcon.png' ></img>
                                        <p style={{ color: 'white', fontSize: '15px', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>Digital Marketing</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* /* fourth card   */}

                        <div class="col">
                            <div className='flippable-card-container mb-3 mt-3'>
                                <div className='cards'>
                                    <div className='card-back' >
                                        <img alt="" src='SeoContent.png'></img>
                                        <p style={{ color: 'grey', fontSize: '15px', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>SEO & Content Writing</p>
                                    </div>
                                    <div className='card-front'>
                                        <img alt="" className='center' src='SeoContent.png' ></img>
                                        <p style={{ color: 'white', fontSize: '15px', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>SEO & Content Writing</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* /* fifth card   */}

                        <div className="col">
                            <div className='flippable-card-container mb-3 mt-3'>
                                <div className='cards'>
                                    <div className='card-back' >
                                        <img alt="" src='appDevelopment.png'></img>
                                        <p style={{ color: 'grey', fontSize: '15px', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>Graphic Design</p>
                                    </div>
                                    <div className='card-front'>
                                        <img alt="" className='center' src='appDevelopment.png' ></img>
                                        <p style={{ color: 'white', fontSize: '15px', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>Graphic Design</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* /* best website agency  */}


            <div className='container best_website' >
                <div className='row'>
                    <div className='col-lg-6 col-sm-12'>
                        
                        <h2 className="sec_title MB_45" style={{ fontSize: '44px', fontWeight: '400px' }}><b>Unlocking Success With Dart Marketing Solutions Pvt Ltd, Your Trusted Digital Partner</b></h2>
                        <p className="sec_desc" >In today's digital age, a strong online presence is essential for businesses to thrive. Whether you're a budding entrepreneur or an established brand, your journey to success begins with an effective digital marketing strategy. This is where Dart Marketing Solutions Pvt Ltd comes in as your trusted partner, offering a comprehensive range of services to elevate your online presence and drive business growth.</p>
                        <Link to='/ABOUT' className="button_out" ><button className='button'>Learn More</button></Link>
                    </div>

                    <div className='col-lg-6  col-sm-12 mt-5' >
                        <div className='img1'>
                            <img src='Agencies1.jpg' alt=''></img>
                        </div>
                        <div className='img2'>
                            <img src='54-540817_digital-marketing-company-in-bangalore-vpointers.png' alt=''></img>
                        </div>
                    </div>
                </div>
            </div>


            {/* youtube photo */}

            <div className=' text-center Youtube p-5 ' style={{ background: 'linear-gradient(to bottom,rgb(248, 243, 243), #fff)' }}>
                <h4 className="sub" >WHAT WE WORK</h4>
                <h2 className="sec_title MB_45" ><b>DIGITAL EXPERIENCE</b></h2>
                <h4 className="sub_title text-center mb-3"><b>We are committed to providing our customers with exceptional service while </b></h4>
                <h4 className="sub_title text-center"><b>offering our employees the best training. </b></h4>
            </div>
            <div className='container contain'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='img-wrap'>
                            <img src='backgroundImg.jpg' alt="" style={{ width: '100%' }}></img>
                            <div className="play_video">
                                <a className="video_popup" href="https://youtu.be/h018qiaV9Oc?si=DlFq8Mx1d9YHdHnO">
                                    < BsFillCollectionPlayFill className='videoImg' />
                                </a>
                                <h2><b>Watch Video</b></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            {/* WORK SHOWCASE */}

            {/* 
            <div className='container-fluid work_show' >
                <div className=' text-center sec ' >
                    <h4 className="sub" >OUR PORTFOLIO</h4>
                    <h2 className="" style={{ fontSize: '44px', fontWeight: '400px' }}><b>WORK SHOWCASE</b></h2>
                    <h4 className="sub_title text-center mb-3"><b>We are committed to providing our customers with exceptional service while </b></h4>
                    <h4 className="sub_title text-center"><b>offering our employees the best training. </b></h4>
                </div>
            </div>
            <div className='container mt-3 workShowImage' >
                <div className='row'>
                    <div className='col-lg-4 col-md-6 col-sm-12 mt-3' >
                        <img src='im4.jpg' alt='' style={{ width: '350px', height: '406px' }} ></img>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 mt-3' >
                        <img src='img5.jpg' alt='' style={{ width: '350px', height: '406px' }}></img>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                        <img src='im3.jpg' alt='' style={{ width: '350px', height: '406px' }}></img>
                    </div>
                </div>
            </div>

            <div className='container mt-3 workShowImage' >
                <div className='row'>
                    <div className='col-lg-4 col-md-6 col-sm-12 mt-3' >
                        <img src='im4.jpg' alt='' style={{ width: '350px', height: '406px' }} ></img>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 mt-3' >
                        <img src='img5.jpg' alt='' style={{ width: '350px', height: '406px' }}></img>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                        <img src='im3.jpg' alt='' style={{ width: '350px', height: '406px' }}></img>
                    </div>
                </div>
            </div> */}

            <h6 className="SubTitle text-center work_show" style={{ color: 'red', paddingTop: '40px', marginBottom: '-1px' }}>SERVICES WE ARE OFFERING</h6>
            <h1 className='secTitle text-center' style={{ display: 'flex', justifyContent: 'center', fontSize: '44px', fontWeight: '400px' }}>WE DESIGN DIGITAL PRODUCTS THAT</h1>
            <h1 className='secTitle container text-center' style={{ display: 'flex', justifyContent: 'center', fontSize: '44px', marginTop: '-23px' }}> HELP GROW BUSINESSES</h1>
            <p className='container text-center para' style={{ color: 'grey' }}>We are committed to providing our customers with exceptional service
                while offering our employees the best training.</p>

            <div className='container '>
                <div className='row '>
                    {/* <first card></first> */}
                    <div className='col-lg-4 mt-5 mb-3'>
                        <div className='card ServicesCards'>
                            <div className='card-body text-center'>
                                <h3>SEO Marketing</h3>
                                <p style={{ color: 'grey' }}>SEO analysis is the process of closely examinig a website to gain a better understanding of how well the site has been optimized and what can be done to help the site improve</p>
                                <img src='seoIcon.png' style={{ width: '20%', height: '20%' }}></img>
                                <div >
                                    <img src='shadow1.jpg' alt='' style={{ opacity: '0.5', width: '100px', height: '10px' }}></img>
                                </div>
                                <div style={{ marginTop: '35px' }}>
                                    <Link to='/SEO_Services_in_Guntur' style={{ color: 'grey', textDecoration: 'none', marginTop: '35px' }}><p className='Serviceslinks'><b>MORE</b></p></Link>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* second card */}

                    <div className='col-lg-4 mt-5 mb-3' >
                        <div className='card ServicesCards'>
                            <div className='card-body text-center'>
                                <h3 className='mb-3' >PPC Marketing</h3>
                                <p style={{ color: 'grey' }} className='mb-4'>Digital Marketing - A Marketing Agency is a customer -focused SEO company.Our SEO experts work closely with our clients to develop personalized SEO statagies</p>
                                <img src='ppcIcon.png' style={{ width: '20%', height: '20%' }}></img>
                                <div >
                                    <img src='shadow1.jpg' alt='' style={{ opacity: '0.5', width: '100px', height: '10px' }}></img>
                                </div>
                                <div style={{ marginTop: '35px' }}>
                                    <Link to='/PPC_Marketing_services_in_guntur' style={{ color: 'grey', textDecoration: 'none', marginTop: '35px' }}><p className='Serviceslinks'><b>MORE</b></p></Link>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* third card */}

                    <div className='col-lg-4 mt-5 mb-3'>
                        <div className='card ServicesCards'>
                            <div className='card-body text-center'>
                                <h3 className='mb-4'>SMM Marketing</h3>
                                <p className='mb-4' style={{ color: 'grey' }}>Digital Marketing offerce Result-Oriented Digital and social media marketinng with creative designs.Brand Consulting </p>
                                <img src='123025969-hand-clicking-linear-icon-click-finger.png' style={{ width: '20%', height: '20%' }}></img>
                                <div >
                                    <img src='shadow1.jpg' alt='' style={{ opacity: '0.5', width: '100px', height: '10px' }}></img>
                                </div>
                                <div style={{ marginTop: '35px' }}>
                                    <Link to='/Social_Media_Marketing_in_Guntur' style={{ color: 'grey', textDecoration: 'none', marginTop: '35px' }}><p className='Serviceslinks'><b>MORE</b></p></Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* second Row Cards */}

            <div className='container mt-1 '>
                <div className='row mt-1'>
                    {/* <first card></first> */}
                    <div className='col-lg-4 mt-5 mb-3'>
                        <div className='card ServicesCards'>
                            <div className='card-body text-center'>
                                <h3>Content Analysis</h3>
                                <p style={{ color: 'grey' }}>Digital Marketing provides web content Analysis services for your Brand to increase the client business for high ROI.</p>
                                <img src='settingsIcon.png' style={{ width: '20%', height: '20%' }}></img>
                                <div >
                                    <img src='shadow1.jpg' alt='' style={{ opacity: '0.5', width: '100px', height: '10px' }}></img>
                                </div>
                                <div style={{ marginTop: '35px' }}>
                                    <Link to='/Content_Analysis_in_Guntur' style={{ color: 'grey', textDecoration: 'none', marginTop: '35px' }}><p className='Serviceslinks'><b>MORE</b></p></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* second card */}

                    <div className='col-lg-4 mt-5 mb-3' >
                        <div className='card ServicesCards'>
                            <div className='card-body text-center'>
                                <h3>Graphic Designing</h3>
                                <p style={{ color: 'grey' }}>Digital Marketing offers Result -Oriented Digital And Social Media marketing with creative designs Brand Consulting.</p>
                                <img src='graphicDesignIcon.png' style={{ width: '19%', height: '19%' }}></img>
                                <div >
                                    <img src='shadow1.jpg' alt='' style={{ opacity: '0.5', width: '100px', height: '10px' }}></img>
                                </div>
                                <div style={{ marginTop: '35px' }}>
                                    <Link to='/Graphic_Designing_Services_in_Guntur' style={{ color: 'grey', textDecoration: 'none', marginTop: '35px' }}><p className='Serviceslinks'><b>MORE</b></p></Link>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* third card */}

                    <div className='col-lg-4 mt-5 mb-3'>
                        <div className='card ServicesCards'>
                            <div className='card-body text-center'>
                                <h3>Web Design & Dev</h3>
                                <p style={{ color: 'grey' }}>Digital Marketing offers a variety of Website Designs and development Services,from creating mobile web development solutions and responsive website designs </p>
                                <img src='seoLayerIcon.png' style={{ width: '15%', height: '15%' }}></img>
                                <div >
                                    <img src='shadow1.jpg' alt='' style={{ opacity: '0.5', width: '100px', height: '10px' }}></img>
                                </div>
                                <div style={{ marginTop: '25px' }}>
                                    <Link to='/Web_Design_Development_Services_in_Guntur' style={{ color: 'grey', textDecoration: 'none', marginTop: '35px' }}><p className='Serviceslinks'><b>MORE</b></p></Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* Third Row Cards */}

            <div className='container mt-1 mb-5'>
                <div className='row mt-1'>
                    {/* <first card></first> */}
                    <div className='col-lg-4 mt-5 mb-3'>
                        <div className='card ServicesCards'>
                            <div className='card-body text-center'>
                                <h3>Ecommerce website</h3>
                                <p style={{ color: 'grey' }}>Ecommerce Website that meets the needs of your audience.It shoud be visually appealing and have a user experience that leads to conversions. </p>
                                <img src='development.png' style={{ width: '20%', height: '20%' }}></img>
                                <div >
                                    <img src='shadow1.jpg' alt='' style={{ opacity: '0.5', width: '100px', height: '10px' }}></img>
                                </div>
                                <div style={{ marginTop: '35px' }}>
                                    <Link to='/E_Commerce_Websites_Development_Services_Guntur' style={{ color: 'grey', textDecoration: 'none', marginTop: '35px' }}><p className='Serviceslinks'><b>MORE</b></p></Link>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* second card */}

                    <div className='col-lg-4 mt-5 mb-3' >
                        <div className='card ServicesCards'>
                            <div className='card-body text-center'>
                                <h3 className='mb-2'>Logo Designing</h3>
                                <p style={{ color: 'grey' }}>We are a logo design company, helping you promote and take your brand to new heights. eDigital Consultants provides creative logo designing services in Hyderabad.</p>
                                <img src='logoIcon.png' style={{ width: '15%', height: '15%' }}></img>
                                <div >
                                    <img src='shadow1.jpg' alt='' style={{ opacity: '0.5', width: '100px', height: '10px' }}></img>
                                </div>
                                <div style={{ marginTop: '35px' }}>
                                    <Link to='/Logo_Designing_Services_in_Guntur' style={{ color: 'grey', textDecoration: 'none', marginTop: '35px' }}><p className='Serviceslinks'><b>MORE</b></p></Link>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* third card */}

                    <div className='col-lg-4 mt-5 mb-3'>
                        <div className='card ServicesCards'>
                            <div className='card-body text-center'>
                                <h3>Branding</h3>
                                <p className='mb-4' style={{ color: 'grey' }}>Your customers make an opinion about your brand within the first few seconds of interacting with you. We make sure it is good.</p>
                                <img src='branding.png' style={{ width: '20%', height: '20%' }}></img>
                                <div >
                                    <img src='shadow1.jpg' alt='' style={{ opacity: '0.5', width: '100px', height: '10px' }}></img>
                                </div>
                                <div style={{ marginTop: '35px' }}>
                                    <Link to='/Branding_Services_In_Guntur' style={{ color: 'grey', textDecoration: 'none', marginTop: '35px' }}><p className='Serviceslinks'><b>MORE</b></p></Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            {/* counting up */}

            {/* <div className="container-fluid mt-5 mb-5 " style={{ textAlign: 'center', backgroundColor: '#000' }}>

                <div className="row" style={{ paddingTop: '120px', paddingBottom: '190px' }} >
                    <div className="col-md-3">
                        <ScrollTrigger onEnter={() => setcounterstart(true)} onExit={() => setcounterstart(false)}>
                            <div className="Counter">
                                <h1 className='value' style={{ color: '#fff', fontSize: '70px' }}>
                                    {counterstart && <CountUp start={0} end={30} duration={2} delay={0} />}
                                </h1>
                                <h4 className="sub_title" style={{ color: 'grey' }}><b>PROJECTS COMPLETED</b></h4>
                            </div>
                        </ScrollTrigger>
                    </div>
                    <div className="col-md-3">
                        <ScrollTrigger onEnter={() => setcounterstart(true)} onExit={() => setcounterstart(false)}>
                            <div className="Counter">
                                <h1 className='value' style={{ color: '#fff', fontSize: '70px' }}>
                                    {counterstart && <CountUp start={0} end={20} duration={2} delay={0} />}
                                </h1>
                                <h4 className="sub_title" style={{ color: 'grey' }}><b>ACTIVE CLIENTS</b></h4>
                            </div>
                        </ScrollTrigger>
                    </div>

                    <div className="col-md-3">
                        <ScrollTrigger onEnter={() => setcounterstart(true)} onExit={() => setcounterstart(false)}>
                            <div className="Counter">
                                <h1 className='value' style={{ color: '#fff', fontSize: '70px' }}>
                                    {counterstart && <CountUp start={0} end={15} duration={2} delay={0} />}
                                </h1>
                                <h4 className="sub_title" style={{ color: 'grey' }}><b>TEAM MEMBERS</b></h4>
                            </div>
                        </ScrollTrigger>
                    </div>


                    <div className="col-md-3">
                        <ScrollTrigger onEnter={() => setcounterstart(true)} onExit={() => setcounterstart(false)}>
                            <div className="Counter">
                                <h1 className='value' style={{ color: '#fff', fontSize: '70px' }}>
                                    {counterstart && <CountUp start={0} end={30} duration={2} delay={0} />}
                                </h1>
                                <h4 className="sub_title" style={{ color: 'grey' }}><b>SATISFIED CLIENTS</b></h4>
                            </div>
                        </ScrollTrigger>
                    </div>
                </div>
            </div> */}

            {/* <WE ARE TRUSTED BY THE MORE THEN */}



            {/* <section className="commonSection trustClient undefined" style={{ marginBottom: '100px' }}>
                <div className="container" >
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="CL_content">
                                <img src="img1.jpg" alt="" />
                                <div className="abc_inner">
                                    <div className="row">
                                        <div className="col-lg-5 col-sm-12 col-md-5">
                                        </div>
                                        <div className="col-lg-7 col-sm-12 col-md-7" >
                                            <div className="abci_content">
                                                <h2 className="sec_title MB_45 text-center" ><b>WE ARE TRUSTED BY MORE THAN 8900 CLIENTS</b></h2>
                                                <p className='text-center' style={{ color: 'grey' }}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercita-tion ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                                <Link to={""} className="common_btn red_bg button_out" ><button className='button'>Learn More</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* swipper cards... MEET EXPERT PEOPLE*/}


            {/* <section  className='MeetTeam'>
                <div className='container text-center  sec'>
                    <h4 className="sub_title MeetTeam">MEET THE TEAM</h4>
                    <h2 className="sec_title MB_45" style={{ fontSize: '45px' }}><b>EXPERT PEOPLE</b></h2>
                    <h4 className="sub_title text-center"><b>We are committed to providing our customers with exceptional service
                    </b></h4>
                </div>


                <div className='container swip mt-5' style={{ marginBottom: '120px' }}>
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar]}
                        spaceBetween={30}
                        slidesPerView={5}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,

                                spaceBetween: 20
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 4,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 5,
                                
                            }
                        }}
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        <SwiperSlide>
                            
                                <div className='employee mb-5'>
                                    <div className='image'>
                                        <img src='images.png' alt=''></img>
                                    </div>

                                    <div className='overlay'>
                                        <h6 className="sec_title MB_45" style={{color:'white'}}>
                                            Name
                                        </h6>
                                        <h4 className="sub_title" style={{color:'white'}}>working</h4>
                                        <div className='link mt-3'  >
                                            <Link to='' title='Facebook'><BsFacebook className='m-2' style={{color:'white'}} /></Link>
                                            <Link to='/' title='Twitter'><AiOutlineTwitter className='m-2' style={{color:'white'}}/></Link>
                                            <Link to='' title='Instagram' ><BsInstagram className='m-2' style={{color:'white'}}/></Link>

                                        </div>
                                    </div>
                                </div>
                           
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className='employee mb-5'>
                                    <div className='image'>
                                        <img src='images.png' alt=''></img>
                                    </div>

                                    <div className='overlay'>
                                        <h6 className="sec_title MB_45" style={{color:'white'}}>
                                            Name
                                        </h6>
                                        <h4 className="sub_title" style={{color:'white'}}>working</h4>
                                        <div className='link mt-3'  >
                                            <Link to='' title='Facebook'><BsFacebook className='m-2' style={{color:'white'}} /></Link>
                                            <Link to='/' title='Twitter'><AiOutlineTwitter className='m-2' style={{color:'white'}}/></Link>
                                            <Link to='' title='Instagram' ><BsInstagram className='m-2' style={{color:'white'}}/></Link>

                                        </div>
                                    </div>
                                </div>
                           
                           </SwiperSlide>
                        <SwiperSlide>
                        <div className='employee mb-5'>
                                    <div className='image'>
                                        <img src='images.png' alt=''></img>
                                    </div>

                                    <div className='overlay'>
                                        <h6 className="sec_title MB_45" style={{color:'white'}}>
                                            Name
                                        </h6>
                                        <h4 className="sub_title" style={{color:'white'}}>working</h4>
                                        <div className='link mt-3'  >
                                            <Link to='' title='Facebook'><BsFacebook className='m-2' style={{color:'white'}} /></Link>
                                            <Link to='/' title='Twitter'><AiOutlineTwitter className='m-2' style={{color:'white'}}/></Link>
                                            <Link to='' title='Instagram' ><BsInstagram className='m-2' style={{color:'white'}}/></Link>

                                        </div>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className='employee mb-5'>
                                    <div className='image'>
                                        <img src='images.png' alt=''></img>
                                    </div>

                                    <div className='overlay'>
                                        <h6 className="sec_title MB_45" style={{color:'white'}}>
                                            Name
                                        </h6>
                                        <h4 className="sub_title" style={{color:'white'}}>working</h4>
                                        <div className='link mt-3'  >
                                            <Link to='' title='Facebook'><BsFacebook className='m-2' style={{color:'white'}} /></Link>
                                            <Link to='/' title='Twitter'><AiOutlineTwitter className='m-2' style={{color:'white'}}/></Link>
                                            <Link to='' title='Instagram' ><BsInstagram className='m-2' style={{color:'white'}}/></Link>

                                        </div>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className='employee mb-5 '>
                                    <div className='image'>
                                        <img src='images.png' alt=''></img>
                                    </div>

                                    <div className='overlay'>
                                        <h6 className="sec_title MB_45" style={{color:'white'}}>
                                            Name
                                        </h6>
                                        <h4 className="sub_title" style={{color:'white'}}>working</h4>
                                        <div className='link mt-3'  >
                                            <Link to='' title='Facebook'><BsFacebook className='m-2' style={{color:'white'}} /></Link>
                                            <Link to='/' title='Twitter'><AiOutlineTwitter className='m-2' style={{color:'white'}}/></Link>
                                            <Link to='' title='Instagram' ><BsInstagram className='m-2' style={{color:'white'}}/></Link>

                                        </div>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className='employee mb-5'>
                                    <div className='image'>
                                        <img src='images.png' alt=''></img>
                                    </div>

                                    <div className='overlay'>
                                        <h6 className="sec_title MB_45" style={{color:'white'}}>
                                            Name
                                        </h6>
                                        <h4 className="sub_title" style={{color:'white'}}>working</h4>
                                        <div className='link mt-3'  >
                                            <Link to='' title='Facebook'><BsFacebook className='m-2' style={{color:'white'}} /></Link>
                                            <Link to='/' title='Twitter'><AiOutlineTwitter className='m-2' style={{color:'white'}}/></Link>
                                            <Link to='' title='Instagram' ><BsInstagram className='m-2' style={{color:'white'}}/></Link>

                                        </div>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className='employee mb-5'>
                                    <div className='image'>
                                        <img src='images.png' alt=''></img>
                                    </div>

                                    <div className='overlay'>
                                        <h6 className="sec_title MB_45" style={{color:'white'}}>
                                            Name
                                        </h6>
                                        <h4 className="sub_title" style={{color:'white'}}>working</h4>
                                        <div className='link mt-3'  >
                                            <Link to='' title='Facebook'><BsFacebook className='m-2' style={{color:'white'}} /></Link>
                                            <Link to='/' title='Twitter'><AiOutlineTwitter className='m-2' style={{color:'white'}}/></Link>
                                            <Link to='' title='Instagram' ><BsInstagram className='m-2' style={{color:'white'}}/></Link>

                                        </div>
                                    </div>
                                </div>
                        </SwiperSlide>

                    </Swiper>
                </div>

                {/* <div className='container mt-3 mb-2'>
                    <div className='row'>
                        <div className='col'>
                            <div className='card'>
                                <img src='images.png' alt='' ></img>
                                <h6 className="sec_title MB_45" style={{ fontSize: '30px', marginBottom: '2px' }}>name</h6>
                                <h4 className="sub_title" style={{ marginBottom: '40px' }}>working</h4>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='card'>
                                <img src='images.png' alt='' ></img>
                                <h6 className="sec_title MB_45" style={{ fontSize: '30px', marginBottom: '2px' }}>name</h6>
                                <h4 className="sub_title" style={{ marginBottom: '40px' }}>working</h4>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='card'>
                                <img src='images.png' alt='' ></img>
                                <h6 className="sec_title MB_45" style={{ fontSize: '30px', marginBottom: '2px' }}>name</h6>
                                <h4 className="sub_title" style={{ marginBottom: '40px' }}>working</h4>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='card'>
                                <img src='images.png' alt='' ></img>
                                <h6 className="sec_title MB_45" style={{ fontSize: '30px', marginBottom: '2px' }}>name</h6>
                                <h4 className="sub_title" style={{ marginBottom: '40px' }}>working</h4>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='card'>
                                <img src='images.png' alt='' ></img>
                                <h6 className="sec_title MB_45" style={{ fontSize: '30px', marginBottom: '2px' }}>name</h6>
                                <h4 className="sub_title" style={{ marginBottom: '40px' }}>working</h4>
                            </div>
                        </div>
                    </div>
                </div> 
            </section>*/}

            {/* <section className='container-fluid Clients' style={{ background: 'linear-gradient(to bottom,rgb(248, 243, 243), #fff)', paddingTop: '100px' }}>
                <div className='container text-center sec'>
                    <h4 className="sub_title OurClient">OUR CLIENTS</h4>
                    <h2 className="" style={{ fontSize: '45px' }}><b>THEY TRUST US</b></h2>
                    <h4 className="sub_title text-center"><b>We are committed to providing our customers with exceptional service </b></h4>
                    <h4 className="sub_title text-center"><b> while offering our employees the best training.</b></h4>
                </div>

                <div className='container swip mt-5' style={{ marginBottom: '120px' }}>
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar]}
                        spaceBetween={50}
                        slidesPerView={4}

                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,

                                spaceBetween: 20,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 4,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 5,
                                spaceBetween: 50,
                            }
                        }}
                    >
                        <SwiperSlide>
                            <div className='card' style={{ marginBottom: '35px' }}>
                                <div className='card-body text-center'>
                                    <img src='DhruvaConstruction.jpg' alt='' ></img>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card ' style={{ marginBottom: '35px', backgroundColor: 'black' }}>
                                <div className='card-body text-center'>
                                    <img src='DHRUVA V.png' alt='' style={{ marginTop: '-5px' }}></img>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card' style={{ marginBottom: '35px' }}>
                                <div className='card-body text-center'>
                                    <img src='udaylogo.png' alt='' ></img>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card' style={{ marginBottom: '35px' }}>
                                <div className='card-body text-center'>
                                    <img src='Patashala-AIO-e1655744952698.png' alt='' ></img>
                                </div>
                            </div>
                        </SwiperSlide>


                        <SwiperSlide>
                            <div className='card ' style={{ marginBottom: '35px' }}>
                                <div className='card-body text-center'>
                                    <img src='Shrilogo.png' alt='' ></img>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card ' style={{ marginBottom: '35px' }}>
                                <div className='card-body text-center'>
                                    <img src='Help LOGO (1).png' alt='' ></img>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card ' style={{ marginBottom: '35px' }}>
                                <div className='card-body text-center'>
                                    <img src='logoNatis1.png' alt='' style={{ height: '70%', width: '70%', marginTop: '-5px' }}></img>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card ' style={{ marginBottom: '35px' }}>
                                <div className='card-body text-center'>
                                    <img src='ReveraLogo.png' alt='' ></img>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card' style={{ marginBottom: '35px' }} >
                                <div className='card-body text-center'>
                                    <img src='komalilogo.jpg' alt='' ></img>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card ' style={{ marginBottom: '35px' }}>
                                <div className='card-body text-center'>
                                    <img src='kanumuri.png' alt='' ></img>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card ' style={{ marginBottom: '35px' }}>
                                <div className='card-body text-center'>
                                    <img src='vedantha.png' alt='' ></img>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card ' style={{ marginBottom: '35px' }}>
                                <div className='card-body text-center'>
                                    <img src='logoNaraya.png' alt='' ></img>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card ' style={{ marginBottom: '35px' }}>
                                <div className='card-body text-center'>
                                    <img src='aarogya1.jpg' alt='' style={{ marginTop: '-5px' }}></img>
                                </div>
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div>
            </section> */}





            {/* <section className="container commonSectioner testimonial" >
                <div className="container">
                    <div className="row">
                        <div className="text-center col-lg-10 col-sm-12 offset-lg-1">
                            <div className="testimonial_content"><div class="testi_icon">
                                <i className="mei-team"></i>
                            </div>
                                <h2 style={{ fontSize: '35px', color: 'white', padding: '10px 100px 0px 100px' }}><b>Great things in business are never done by one person.<span style={{ color: 'red' }}>They’re done by a team of people.</span></b></h2>
                                <p style={{ color: '#fff', padding: '0px 200px 0px 200px' }}>We are committed to providing our customers with exceptional service while
                                    offering our employees the best training.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>  */}
            <section className='container-fluid Clients mb-5' style={{ background: 'linear-gradient(to bottom,rgb(248, 243, 243), #fff)', paddingTop: '100px' }}>
                <div className='container text-center sec'>

                    <h2 className="mt-5" style={{ fontSize: '45px' }}><b>LATEST BLOGS</b></h2>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-lg-4 col-sm-12 '>
                            <div className='card text-center mb-3'>
                                <div className='card-title'>
                                    <h4>coming soon..</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-sm-12'>
                            <div className='card text-center mb-3'>
                                <div className='card-title'>
                                    <h4>coming soon..</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-sm-12'>
                            <div className='card text-center mb-3'>
                                <div className='card-title'>
                                    <h4>coming soon..</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="commonSections ready" style={{ backgroundColor: 'red', padding: '80px 0 60px 0' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-sm-8 col-md-9">
                            <h2 className="sec_title white">Let's Get Your Project Started!</h2>
                        </div>
                        <div className="col-lg-3  col-sm-4 col-md-3 text-right">
                            <Link to="/contact"><button className='button' ><span>Contact with us</span></button></Link>
                        </div>
                    </div>
                </div>
            </section>

        </>

    )



}

export default Home