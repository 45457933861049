import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { CiMenuBurger } from 'react-icons/ci';
import { TbSettingsCog } from 'react-icons/tb'
import { Carousel } from 'react-bootstrap';
import './Services.css';

const Services = () => {
    const [menubar, setmenubar] = useState(false)
    const [menubar1, setmenubar1] = useState(false)

    const setmenubarFunction = () => {
        setmenubar(!menubar);
        setmenubar1(!menubar1);
    }


    const [navchange, setNavbar] = useState(false);




    const changebackground = () => {
        if (window.scrollY >= 250) {
            setNavbar(true)
        }
        else {
            setNavbar(false);
        }
    }

    window.addEventListener('scroll', changebackground)
    return (
        <>
            {/* header  part... */}
            <Carousel className='carouse' indicators={false} controls={false}>
                <Carousel.Item className='carouse1' interval={1000}>
                    <img
                        className="d-block w-100"
                        src="banner.jpg"
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Link className='p-2 text-center text' to='/Home' style={{ textDecoration: 'none' }}><h6>HOME</h6></Link>
                            <h6 className='p-2 text' > - </h6>
                            <Link className='p-2' style={{ textDecoration: 'none', color: 'white' }}><h6>SERVICES</h6></Link>
                        </div>
                        <h3 className='display-1'><b>SERVICES</b></h3>

                    </Carousel.Caption>
                </Carousel.Item>


            </Carousel>
            {/* <div className='carouse' >
                <div className='banner' >
                    <img src='banner.jpg' alt='' style={{ width: '100%',height:'400px' }}></img>
                </div>
                <div className='container text-center linkItems' style={{ position: 'absolute' }} >
                    <div className='text-center' style={{ display: 'flex'}}>
                        <Link className='p-2 text-center' to='/Home'>Home </Link>
                        <p className='p-2'> - </p>
                        <Link className='p-2'>Services</Link>
                    </div>

                </div>


            </div> */}
            <div className='navs fixed-top shadow-lg  rounded'>
                <nav className={navchange ? 'navbar active' : 'navbar'} >
                    <div class="container-fluid">
                        <div class="navbar-header">
                            <Link className='navbar-brand'><img src='white logo final DART LOGO.png' alt='' style={{ width: '150px', height: '80px', paddingTop: '0px', marginTop: '0px' }}></img></Link>
                        </div>
                        <div className='megaLinks'>
                            <ul className={menubar ? 'nav-links' : 'nav-link'} style={{ listStyleType: 'none',marginTop:'15px'  }}>
                                <li >
                                    <Link to="/" style={{ textDecoration: 'none', marginLeft: '50px', color: 'white' }}><b>HOME</b></Link>
                                </li>
                                <li>
                                    <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white' }}><b>ABOUT</b></Link>
                                </li>
                                <li>
                                    <Link to="/Services" style={{ textDecoration: 'none', color: 'white' }}><b>SERVICES</b></Link>
                                </li>
                              
                                <li>
                                    <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white' }}><b>INDUSTRIES</b></Link>
                                </li>
                                <li>
                                    <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white' }}><b>BLOGS</b></Link>
                                </li>
                                <li>
                                    <Link to="/Contact" style={{ textDecoration: 'none', color: 'white' }}><b>CONTACT US</b></Link>
                                </li>
                            </ul>
                        </div>

                        <div className='ms-auto'>
                            <CiMenuBurger onClick={setmenubarFunction} style={{ margin: '30px', width: '30px', height: '30px', color: 'white' }} />
                        </div>
                    </div>



                </nav>
                <div className={menubar1 ? 'nav-linked' : 'naved'} >
                    <ul style={{ listStyleType: 'none', alignContent: 'center' }} >
                        <li >
                            <Link to="/" style={{ textDecoration: 'none', color: 'white', textAlign: 'center', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}><b>HOME</b></Link>
                        </li>
                        <li>
                            <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>ABOUT</Link>
                        </li>
                        <li>
                            <Link to="/Services" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>SERVICES</Link>
                        </li>
                       
                        <li>
                            <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>INDUSTRIES</Link>
                        </li>
                        <li>
                            <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>BLOGS</Link>
                        </li>
                        <li>
                            <Link to="/Contact" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>CONTACT US</Link>
                        </li>
                    </ul>


                    <div className='container mt-5 p-5' >
                        <div className='row'>
                            <div className='col' style={{ color: 'black' }}>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Phone: 093934 15155</h4>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Email: dartmarketing.in@gmail.com</h4>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '17px' }}>Address:11/2, D.No. 6-11-53/A, 1st Floor, Arundelpet, Guntur, IN - 522002</h4>
                            </div>

                            <div className='container col text-center' style={{ color: 'white', textAlign: 'center' }}>
                                <ul style={{ listStyleType: 'none', display: 'flex' }}>
                                    <li>
                                        <Link to='https://www.facebook.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>facebook</Link></li>
                                    <li><Link to='https://www.instagram.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>youtube</Link></li>
                                    <li><Link style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>twitter</Link></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            {/* first Row cards  */}

            <h6 className="SubTitle text-center" style={{ color: 'red', paddingTop: '40px' }}>SERVICES WE ARE OFFERING</h6>
                <h1 className='secTitle text-center' style={{ display: 'flex', justifyContent: 'center', fontSize: '44px', fontWeight: '400px' }}>WE DESIGN DIGITAL PRODUCTS THAT</h1>
                <h1 className='secTitle container text-center' style={{ display: 'flex',  justifyContent: 'center', fontSize: '44px', marginTop: '-23px' }}> HELP GROW BUSINESSES</h1>
                <p className='container text-center para' style={{ color: 'grey' }}>We are committed to providing our customers with exceptional service
                    while offering our employees the best training.</p>

            <div className='container '>
                <div className='row '>
                    {/* <first card></first> */}
                    <div className='col-lg-4 mt-5 mb-3'>
                        <div className='card ServicesCards'>
                            <div className='card-body text-center'>
                                <h3>SEO Marketing</h3>
                                <p style={{ color: 'grey' }}>SEO analysis is the process of closely examinig a website to gain a better understanding of how well the site has been optimized and what can be done to help the site improve</p>
                                <img src='seoIcon.png' style={{width:'20%', height:'20%'}}></img>
                                <div >
                                    <img src='shadow1.jpg' alt='' style={{ opacity: '0.5', width: '100px', height: '10px' }}></img>
                                </div>
                                <div style={{ marginTop: '35px' }}>
                                    <Link  to='/SEO_Services_in_Guntur' style={{ color: 'grey', textDecoration: 'none', marginTop: '35px' }}><p className= 'Serviceslinks'><b>MORE</b></p></Link>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* second card */}

                    <div className='col-lg-4 mt-5 mb-3' >
                        <div className='card ServicesCards'>
                            <div className='card-body text-center'>
                                <h3 className='mb-3' >PPC Marketing</h3>
                                <p style={{ color: 'grey' }} className='mb-4'>Digital Marketing - A Marketing Agency is a customer -focused SEO company.Our SEO experts work closely with our clients to develop personalized SEO statagies</p>
                                <img src='ppcIcon.png' style={{width:'20%', height:'20%'}}></img>
                                <div >
                                    <img src='shadow1.jpg' alt='' style={{ opacity: '0.5', width: '100px', height: '10px' }}></img>
                                </div>
                                <div style={{ marginTop: '35px' }}>
                                    <Link  to='/PPC_Marketing_services_in_guntur' style={{ color: 'grey', textDecoration: 'none', marginTop: '35px' }}><p className= 'Serviceslinks'><b>MORE</b></p></Link>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* third card */}

                    <div className='col-lg-4 mt-5 mb-3'>
                        <div className='card ServicesCards'>
                            <div className='card-body text-center'>
                                <h3 className='mb-4'>SMM Marketing</h3>
                                <p className='mb-4' style={{ color: 'grey' }}>Digital Marketing offerce Result-Oriented Digital and social media marketinng with creative designs.Brand Consulting </p>
                                <img src='123025969-hand-clicking-linear-icon-click-finger.png' style={{width:'20%', height:'20%'}}></img>
                                <div >
                                    <img src='shadow1.jpg' alt='' style={{ opacity: '0.5', width: '100px', height: '10px' }}></img>
                                </div>
                                <div style={{ marginTop: '35px' }}>
                                    <Link  to='/Social_Media_Marketing_in_Guntur' style={{ color: 'grey', textDecoration: 'none', marginTop: '35px' }}><p className= 'Serviceslinks'><b>MORE</b></p></Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* second Row Cards */}

            <div className='container mt-1 '>
                <div className='row mt-1'>
                    {/* <first card></first> */}
                    <div className='col-lg-4 mt-5 mb-3'>
                        <div className='card ServicesCards'>
                            <div className='card-body text-center'>
                                <h3>Content Analysis</h3>
                                <p style={{ color: 'grey' }}>Digital Marketing provides web content Analysis services for your Brand to increase the client business for high ROI.</p>
                                <img src='settingsIcon.png' style={{width:'20%', height:'20%'}}></img>
                                <div >
                                    <img src='shadow1.jpg' alt='' style={{ opacity: '0.5', width: '100px', height: '10px' }}></img>
                                </div>
                                <div style={{ marginTop: '35px' }}>
                                    <Link  to='/Content_Analysis_in_Guntur' style={{ color: 'grey', textDecoration: 'none', marginTop: '35px' }}><p className= 'Serviceslinks'><b>MORE</b></p></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* second card */}

                    <div className='col-lg-4 mt-5 mb-3' >
                        <div className='card ServicesCards'>
                            <div className='card-body text-center'>
                                <h3>Graphic Designing</h3>
                                <p style={{ color: 'grey' }}>Digital Marketing offers Result -Oriented Digital And Social Media marketing with creative designs Brand Consulting.</p>
                                <img src='graphicDesignIcon.png' style={{width:'19%', height:'19%'}}></img>
                                <div >
                                    <img src='shadow1.jpg' alt='' style={{ opacity: '0.5', width: '100px', height: '10px' }}></img>
                                </div>
                                <div style={{ marginTop: '35px' }}>
                                    <Link  to='/Graphic_Designing_Services_in_Guntur' style={{ color: 'grey', textDecoration: 'none', marginTop: '35px' }}><p className= 'Serviceslinks'><b>MORE</b></p></Link>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* third card */}

                    <div className='col-lg-4 mt-5 mb-3'>
                        <div className='card ServicesCards'>
                            <div className='card-body text-center'>
                                <h3>Web Design & Dev</h3>
                                <p style={{ color: 'grey' }}>Digital Marketing offers a variety of Website Designs and development Services,from creating mobile web development solutions and responsive website designs </p>
                                <img src='seoLayerIcon.png' style={{width:'15%', height:'15%'}}></img>
                                <div >
                                    <img src='shadow1.jpg' alt='' style={{ opacity: '0.5', width: '100px', height: '10px' }}></img>
                                </div>
                                <div style={{ marginTop: '25px' }}>
                                    <Link  to='/Web_Design_Development_Services_in_Guntur' style={{ color: 'grey', textDecoration: 'none', marginTop: '35px' }}><p className= 'Serviceslinks'><b>MORE</b></p></Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* Third Row Cards */}

            <div className='container mt-1 mb-5'>
                <div className='row mt-1'>
                    {/* <first card></first> */}
                    <div className='col-lg-4 mt-5 mb-3'>
                        <div className='card ServicesCards'>
                            <div className='card-body text-center'>
                                <h3>Ecommerce website</h3>
                                <p style={{ color: 'grey' }}>Ecommerce Website that meets the needs of your audience.It shoud be visually appealing and have a user experience that leads to conversions. </p>
                                <img src='development.png' style={{width:'20%', height:'20%'}}></img>
                                <div >
                                    <img src='shadow1.jpg' alt='' style={{ opacity: '0.5', width: '100px', height: '10px' }}></img>
                                </div>
                                <div style={{ marginTop: '35px' }}>
                                    <Link  to='/E_Commerce_Websites_Development_Services_Guntur' style={{ color: 'grey', textDecoration: 'none', marginTop: '35px' }}><p className= 'Serviceslinks'><b>MORE</b></p></Link>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* second card */}

                    <div className='col-lg-4 mt-5 mb-3' >
                        <div className='card ServicesCards'>
                            <div className='card-body text-center'>
                                <h3 className='mb-2'>Logo Designing</h3>
                                <p style={{ color: 'grey' }}>We are a logo design company, helping you promote and take your brand to new heights. eDigital Consultants provides creative logo designing services in Hyderabad.</p>
                                <img src='logoIcon.png' style={{width:'15%', height:'15%'}}></img>
                                <div >
                                    <img src='shadow1.jpg' alt='' style={{ opacity: '0.5', width: '100px', height: '10px' }}></img>
                                </div>
                                <div style={{ marginTop: '35px' }}>
                                    <Link  to='/Logo_Designing_Services_in_Guntur' style={{ color: 'grey', textDecoration: 'none', marginTop: '35px' }}><p className= 'Serviceslinks'><b>MORE</b></p></Link>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* third card */}

                    <div className='col-lg-4 mt-5 mb-3'>
                        <div className='card ServicesCards'>
                            <div className='card-body text-center'>
                                <h3>Branding</h3>
                                <p className='mb-4' style={{ color: 'grey' }}>Your customers make an opinion about your brand within the first few seconds of interacting with you. We make sure it is good.</p>
                                <img src='branding.png' style={{width:'20%', height:'20%'}}></img>
                                <div >
                                    <img src='shadow1.jpg' alt='' style={{ opacity: '0.5', width: '100px', height: '10px' }}></img>
                                </div>
                                <div style={{ marginTop: '35px' }}>
                                    <Link  to='/Branding_Services_In_Guntur' style={{ color: 'grey', textDecoration: 'none', marginTop: '35px' }}><p className= 'Serviceslinks'><b>MORE</b></p></Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* <div className='container-fluid' style={{ backgroundColor: 'black' }}>
                <h6 className="SubTitle text-center" style={{ color: 'red', paddingTop: '120px' }}>SERVICES WE ARE OFFERING</h6>
                <h1 className='secTitle text-center' style={{ display: 'flex', color: 'white', justifyContent: 'center', fontSize: '44px', fontWeight: '400px' }}>WE DESIGN DIGITAL PRODUCTS THAT</h1>
                <h1 className='secTitle container text-center' style={{ display: 'flex', color: 'white', justifyContent: 'center', fontSize: '44px', marginTop: '-23px' }}> HELP GROW BUSINESSES</h1>
                <p className='container text-center para' style={{ color: 'grey' }}>We are committed to providing our customers with exceptional service
                    while offering our employees the best training.</p>

                {/* /* first card   */}

                {/* <div className='container my-5 ' >
                    <div className='row' style={{ paddingBottom: '80px' }}>
                        <div className="col">
                            <div className='flippable-card-container mb-3 mt-3'>
                                <div className='cards'>
                                    <div className='card-back' >
                                        <img alt="" src='development.png'></img>
                                        <p style={{ color: 'grey', fontSize: '15px', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>Website Development</p>
                                    </div>
                                    <div className='card-front'>

                                        <img alt="" className='center' src='development.png' ></img>
                                        <p style={{ color: 'white', fontSize: '15px', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>Website development</p>

                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* /* second card   */}
{/* 
                        <div class="col" >
                            <div className='flippable-card-container mb-3 mt-3'>
                                <div className='cards'>
                                    <div className='card-back' >
                                        <h3>hello hai this is front card</h3>
                                    </div>
                                    <div className='card-front'>
                                        <h3>hello hai this is back card</h3>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* /* third card   */}
{/* 
                        <div class="col">
                            <div className='flippable-card-container mb-3 mt-3'>
                                <div className='cards'>
                                    <div className='card-back' >
                                        <h3>hello hai this is front card</h3>
                                    </div>
                                    <div className='card-front'>
                                        <h3>hello hai this is back card</h3>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* /* fourth card   */}

                        {/* <div class="col">
                            <div className='flippable-card-container mb-3 mt-3'>
                                <div className='cards'>
                                    <div className='card-back' >
                                        <h3>hello hai this is front card</h3>
                                    </div>
                                    <div className='card-front'>
                                        <h3>hello hai this is back card</h3>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* /* fifth card   */}

                        {/* <div className="col">
                            <div className='flippable-card-container mb-3 mt-3'>
                                <div className='cards'>
                                    <div className='card-back' >
                                        <h3>hello hai this is front card</h3>
                                    </div>
                                    <div className='card-front'>
                                        <h3>hello hai this is back card</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div> */}

{/* 
            <section class="commonSection what_wedo_2 mb-5">
                <div class="container">
                    <div class="row">
                        <div class="text-center col-lg-12">
                            <h4 class="sub_title ">what we do</h4>
                            <h2 class="sec_title ">Let’s create something</h2>
                            <p class="sec_desc ">We are committed to providing our customers with exceptional service
                                while offering our employees the best training.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-12">
                            <div className='card'>
                                <div class="card-body single_wedo text-center">
                                    <img src="im1.jpg" alt="web development" />
                                    <div class="overlay_wedo">
                                        <a href="/service-details">web development</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div className='card'>
                                <div class="single_wedo">
                                    <img src="" alt="web development" />
                                    <div class="overlay_wedo">
                                        <a href="/service-details">web development</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div className='card'>
                                <div class="single_wedo">
                                    <img src="" alt="web development" />
                                    <div class="overlay_wedo">
                                        <a href="/service-details">web development</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    )
}

export default Services