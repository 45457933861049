
import './Services.css';
import './App.css'
import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { CiMenuBurger } from 'react-icons/ci';
import { TbSettingsCog } from 'react-icons/tb'
import { Carousel } from 'react-bootstrap';
import emailjs from '@emailjs/browser';

const Contact = () => {
  // email address

  const form = useRef()

  const [data, updateData] = useState({
    Name: "",
    Email: '',
    Number: '',
    Subject: '',
    Message: ''
  })
  const { Name, Email, Number, Subject, Message } = data;

  const changeHandler = e => {
    updateData({ ...data, [e.target.name]: [e.target.value] })

  }
  const submitHandler = e => {
    e.preventDefault();

    emailjs.sendForm('service_e0g7ddd', 'template_ne47qsf', e.target, 'gsf6KRxqr2rYM4M8V')
      .then((result) => {
        alert("message Sent Succesfully")
        updateData({
          Name: "",
          Email: "",
          Number: "",
          Subject: '',
          Message: ''

        })

      }, (error) => {
        console.log(error.text);
      });

  }

  // navbar usestates
  const [menubar, setmenubar] = useState(false)
  const [menubar1, setmenubar1] = useState(false)

  const setmenubarFunction = () => {
    setmenubar(!menubar);
    setmenubar1(!menubar1);
  }


  const [navchange, setNavbar] = useState(false);




  const changebackground = () => {
    if (window.scrollY >= 250) {
      setNavbar(true)
    }
    else {
      setNavbar(false);
    }
  }

  window.addEventListener('scroll', changebackground)
  return (
    <>
      {/* header  part... */}
      <Carousel className='carouse' indicators={false} controls={false}>
        <Carousel.Item className='carouse1' interval={1000}>
          <img
            className="d-block w-100"
            src="banner.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Link className='p-2 text-center text' to='/Home' style={{ textDecoration: 'none' }}><h6>HOME</h6></Link>
              <h6 className='p-2 text' > - </h6>
              <Link className='p-2' style={{ textDecoration: 'none', color: 'white' }}><h6>CONTACT US</h6></Link>
            </div>
            <h3 className='display-1'><b>CONTACT US</b></h3>

          </Carousel.Caption>
        </Carousel.Item>


      </Carousel>
      {/* <div className='carouse' >
                <div className='banner' >
                    <img src='banner.jpg' alt='' style={{ width: '100%',height:'400px' }}></img>
                </div>
                <div className='container text-center linkItems' style={{ position: 'absolute' }} >
                    <div className='text-center' style={{ display: 'flex'}}>
                        <Link className='p-2 text-center' to='/Home'>Home </Link>
                        <p className='p-2'> - </p>
                        <Link className='p-2'>Services</Link>
                    </div>

                </div>


            </div> */}
      <div className='navs fixed-top shadow-lg  rounded'>
        <nav className={navchange ? 'navbar active' : 'navbar'} >
          <div class="container-fluid">
            <div class="navbar-header">
              <Link className='navbar-brand'><img src='white logo final DART LOGO.png' alt='' style={{ width: '150px', height: '80px', paddingTop: '0px', marginTop: '0px' }}></img></Link>
            </div>
            <div className='megaLinks'>
              <ul className={menubar ? 'nav-links' : 'nav-link'} style={{ listStyleType: 'none',marginTop:'15px' }}>
                <li >
                  <Link to="/" style={{ textDecoration: 'none', marginLeft: '50px', color: 'white' }}><b>HOME</b></Link>
                </li>
                <li>
                  <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white' }}><b>ABOUT</b></Link>
                </li>
                <li>
                  <Link to="/Services" style={{ textDecoration: 'none', color: 'white' }}><b>SERVICES</b></Link>
                </li>
               
                <li>
                  <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white' }}><b>INDUSTRIES</b></Link>
                </li>
                <li>
                  <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white' }}><b>BLOGS</b></Link>
                </li>
                <li>
                  <Link to="/Contact" style={{ textDecoration: 'none', color: 'white' }}><b>CONTACT US</b></Link>
                </li>
              </ul>
            </div>

            <div className='ms-auto'>
              <CiMenuBurger onClick={setmenubarFunction} style={{ margin: '30px', width: '30px', height: '30px', color: 'white' }} />
            </div>
          </div>



        </nav>
        <div className={menubar1 ? 'nav-linked' : 'naved'} >
          <ul style={{ listStyleType: 'none', alignContent: 'center' }} >
            <li >
              <Link to="/" style={{ textDecoration: 'none', color: 'white', textAlign: 'center', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}><b>HOME</b></Link>
            </li>
            <li>
              <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>ABOUT</Link>
            </li>
            <li>
              <Link to="/Services" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>SERVICES</Link>
            </li>
           
            <li>
              <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>INDUSTRIES</Link>
            </li>
            <li>
              <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>BLOGS</Link>
            </li>
            <li>
              <Link to="/Contact" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>CONTACT US</Link>
            </li>
          </ul>


          <div className='container mt-5 p-5' >
            <div className='row'>
              <div className='col' style={{ color: 'black' }}>
                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Phone: 093934 15155</h4>
                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Email: dartmarketing.in@gmail.com</h4>
                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '17px' }}>Address:11/2, D.No. 6-11-53/A, 1st Floor, Arundelpet, Guntur, IN - 522002</h4>
              </div>

              <div className='container col text-center' style={{ color: 'white', textAlign: 'center' }}>
                <ul style={{ listStyleType: 'none', display: 'flex' }}>
                  <li>
                    <Link to='https://www.facebook.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>facebook</Link></li>
                  <li><Link to='https://www.instagram.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>youtube</Link></li>
                  <li><Link style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>twitter</Link></li>

                </ul>
              </div>
            </div>
          </div>
        </div>


      </div>


      <div className=' text-center sec p-5' style={{ background: 'linear-gradient(to bottom,rgb(248, 243, 243), #fff)' }}>
        <h4 className="sub" >CONTACT WITH US</h4>
        <h2 className="sec_title MB_45" style={{ fontSize: '44px', fontWeight: '400px' }}><b>WRITE US A MESSAGE</b></h2>
        <h4 className="sub_title text-center mb-3"><b>We are committed to providing our customers with exceptional service while</b></h4>
        <h4 className="sub_title text-center"><b>offering our employees the best training.</b></h4>
      </div>



      <form ref={form} onSubmit={submitHandler} className='mb-5'>
        <div className="container border w-100 m-auto p-3 " style={{ borderRadius: '20px' }}  >
          <div className="row" >
            <div className="col-lg-6  my-3 ">
              <div className="form-group ">
                <input type="text" value={Name} onChange={changeHandler} className="form-control" title='' placeholder="Your Name" name="Name" required="Username is Required..." message="Username must be atmost 30 characters long..." style={{ padding: '10px' }} />
                <div className="help-block with-errors"></div>
              </div>
            </div>
            <div className="col-lg-6 my-3 ">
              <div className="form-group">
                <input type="email" value={Email} onChange={changeHandler} className="form-control" title='' placeholder="Your Email Address" name="Email" id="email" required="Email is required" data-error="Please enter your email" fdprocessedid="p2hd6j" style={{ padding: '10px' }} />
                <div className="help-block with-errors"></div>
              </div>
            </div>
            <div className="col-lg-6 my-3">
              <div className="form-group">
                <input type="text" value={Number} onChange={changeHandler} className="form-control" title='' name="Number" placeholder="Phone Number" id="phone_number" required="Number is Required" data-error="Please enter your number" fdprocessedid="cf7ig" style={{ padding: '10px' }} />
                <div className="help-block with-errors"></div>
              </div>
            </div>
            <div className="col-lg-6 my-3">
              <div className="form-group">
                <input type="text" value={Subject} onChange={changeHandler} className="form-control" title='' name="Subject" placeholder="Subject" id="msg_subject" required="Subject is Required" data-error="Please enter your subject" fdprocessedid="4mor3e" style={{ padding: '10px' }} />
                <div className="help-block with-errors"></div>
              </div>
            </div>
            <div className="col-lg-12 my-3">
              <div className="form-group">
                <textarea name="Message" value={Message} onChange={changeHandler} className="form-control" id="message" cols="30" rows="6" placeholder="Type Your Message Here" required="Message is required" data-error="Write your message"></textarea>
                <div className="help-block with-errors"></div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <button type="submit" className="btn " title='' name="contact" style={{ backgroundColor: "red", color: "white" }} >Send Message</button>
              <div id="msgSubmit" className="h3 text-center hidden"></div>
              <div className="clearfix"></div>
            </div>

          </div>

        </div>


      </form>

      {/* map address */}

      <div className='mb-5'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3829.377636919775!2d80.42813103821526!3d16.30363753318751!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4a7568372504a1%3A0x103204e4232e6e0b!2sDigital%20Marketing%20in%20Guntur!5e0!3m2!1sen!2sin!4v1697453982542!5m2!1sen!2sin" width="100%" height="450" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>

    </>
  )
}

export default Contact