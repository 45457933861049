import React from 'react'

const Footer = () => {
  return (
    <>
      {/* Footer */}

      <section style={{ backgroundColor: 'black' }}>
                <div className='container' style={{paddingTop:'130px',paddingBottom:'100px'}}>
                    <div className='row'>
                        <div className='col-lg-4  text-center'>
                            <img src='white logo final DART LOGO.png' style={{ width: '150px', height: '100',padding:'10px' }}></img>
                            <p className="" style={{ color: 'grey',letterSpacing:'0.5px' }} ><b>Welcome to our web design agency. At Dart Marketing Solutions Pvt Ltd, we offer a diverse set of services that cater to all your digital marketing needs.</b></p>

                        </div>

                        <div className='col-lg-4  text-center'>
                          <h6 style={{color:'#fff',padding:'23px'}}><b>CONTACT</b></h6>
                          <p className="" style={{ color: 'grey',letterSpacing:'0.5px' }} ><b>11/2, D.No. 6-11-53/A, 1st Floor, Arundelpet, Guntur, </b></p>
                          <p className="" style={{ color: 'grey',letterSpacing:'0.5px' }} ><b>Andhra Pradesh 522002</b></p>
                          <p className="" style={{ color: 'grey',letterSpacing:'0.5px' }} ><b>093934 15155</b></p>
                          <p className="" style={{ color: 'grey',letterSpacing:'0.5px' }} ><b>dartmarketing.in@gmail.com</b></p>
                        </div>

                        <div className='col-lg-4  text-center'>
                          <h6 style={{color:'#fff',padding:'23px'}}><b>SOCIAL</b></h6>
                          <div className='text-center' >
                          <a className='mb-5' href='https://www.facebook.com/dartmarketingsolutions/'style={{ color: 'grey',letterSpacing:'0.5px' }}><b>FACEBOOK</b></a><br></br>
                          <a  href='https://www.instagram.com/dartmarketingsolutions/'style={{ color: 'grey',letterSpacing:'0.5px',marginTop:'50px' }}><b>INSTAGRAM</b></a>

                          </div>
                                                   {/* <p className="" style={{ color: 'grey',letterSpacing:'0.5px' }} ><b>Twitter</b></p>
                          <p className="" style={{ color: 'grey',letterSpacing:'0.5px' }} ><b>Facebook</b></p>
                          <p className="" style={{ color: 'grey',letterSpacing:'0.5px' }} ><b>Youtube</b></p> */}
                          
                        </div>
                    </div>
                </div>
            </section>
 
    </>
  )
}

export default Footer