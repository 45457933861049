import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { CiMenuBurger } from 'react-icons/ci';
import { TbSettingsCog } from 'react-icons/tb'
import { Carousel } from 'react-bootstrap';


const SEO_Services_in_Guntur = () => {
    const [menubar, setmenubar] = useState(false)
    const [menubar1, setmenubar1] = useState(false)

    const setmenubarFunction = () => {
        setmenubar(!menubar);
        setmenubar1(!menubar1);
    }

    const [navchange, setNavbar] = useState(false);



    const changebackground = () => {
        if (window.scrollY >= 250) {
            setNavbar(true)
        }
        else {
            setNavbar(false);
        }
    }

    window.addEventListener('scroll', changebackground)
    return (
        <>
            <Carousel className='carouse' indicators={false} controls={false}>
                <Carousel.Item className='carouse1' interval={1000}>
                    <img
                        className="d-block w-100"
                        src="banner.jpg"
                        alt="First slide"
                    />
                    <Carousel.Caption className='caroselCaption'>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Link className='p-2 text-center text' to='/Home' style={{ textDecoration: 'none' }}><h6>HOME</h6></Link>
                            <h6 className='p-2 text' > - </h6>
                            <Link className='p-2' style={{ textDecoration: 'none', color: 'white' }}><h6>SERVICES</h6></Link>
                        </div>
                        <h3 className='display-1'><b>SEO SERVICES</b></h3>

                    </Carousel.Caption>
                </Carousel.Item>

            </Carousel>
            {/* <div className='carouse' >
              <div className='banner' >
                  <img src='banner.jpg' alt='' style={{ width: '100%',height:'400px' }}></img>
              </div>
              <div className='container text-center linkItems' style={{ position: 'absolute' }} >
                  <div className='text-center' style={{ display: 'flex'}}>
                      <Link className='p-2 text-center' to='/Home'>Home </Link>
                      <p className='p-2'> - </p>
                      <Link className='p-2'>Services</Link>
                  </div>

              </div>

          </div> */}
            <div className='navs fixed-top shadow-lg  rounded'>
                <nav className={navchange ? 'navbar active' : 'navbar'} >
                    <div class="container-fluid">
                        <div class="navbar-header">
                            <Link className='navbar-brand'><img src='white logo final DART LOGO.png' alt='' style={{ width: '150px', height: '80px', paddingTop: '0px', marginTop: '0px' }}></img></Link>
                        </div>
                        <div className='megaLinks'>
                            <ul className={menubar ? 'nav-links' : 'nav-link'} style={{ listStyleType: 'none',marginTop:'15px'  }}>
                                <li >
                                    <Link to="/" style={{ textDecoration: 'none', marginLeft: '50px', color: 'white' }}><b>HOME</b></Link>
                                </li>
                                <li>
                                    <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white' }}><b>ABOUT</b></Link>
                                </li>
                                <li>
                                    <Link to="/Services" style={{ textDecoration: 'none', color: 'white' }}><b>SERVICES</b></Link>
                                </li>
                               
                                <li>
                                    <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white' }}><b>INDUSTRIES</b></Link>
                                </li>
                                <li>
                                    <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white' }}><b>BLOGS</b></Link>
                                </li>
                                <li>
                                    <Link to="/Contact" style={{ textDecoration: 'none', color: 'white' }}><b>CONTACT US</b></Link>
                                </li>
                            </ul>
                        </div>

                        <div className='ms-auto'>
                            <CiMenuBurger onClick={setmenubarFunction} style={{ margin: '30px', width: '30px', height: '30px', color: 'white' }} />
                        </div>
                    </div>


                </nav>
                <div className={menubar1 ? 'nav-linked' : 'naved'} >
                    <ul style={{ listStyleType: 'none', alignContent: 'center' }} >
                        <li >
                            <Link to="/" style={{ textDecoration: 'none', color: 'white', textAlign: 'center', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}><b>HOME</b></Link>
                        </li>
                        <li>
                            <Link to="/ABOUT" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>ABOUT</Link>
                        </li>
                        <li>
                            <Link to="/Services" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>SERVICES</Link>
                        </li>
                       
                        <li>
                            <Link to="/INDUSTRIES" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>INDUSTRIES</Link>
                        </li>
                        <li>
                            <Link to="/Blogs" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>BLOGS</Link>
                        </li>
                        <li>
                            <Link to="/Contact" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>CONTACT US</Link>
                        </li>
                    </ul>

                    <div className='container mt-5 p-5' >
                        <div className='row'>
                            <div className='col' style={{ color: 'black' }}>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Phone: 093934 15155</h4>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>Email: dartmarketing.in@gmail.com</h4>
                                <h4 className="sub_title" style={{ color: 'white', fontWeight: 'bold', fontSize: '17px' }}>Address:11/2, D.No. 6-11-53/A, 1st Floor, Arundelpet, Guntur, IN - 522002</h4>
                            </div>

                            <div className='container col text-center' style={{ color: 'white', textAlign: 'center' }}>
                                <ul style={{ listStyleType: 'none', display: 'flex' }}>
                                    <li>
                                        <Link to='https://www.facebook.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>facebook</Link></li>
                                    <li><Link to='https://www.instagram.com/dartmarketingsolutions/' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>youtube</Link></li>
                                    <li><Link style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>twitter</Link></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='container mt-5'>
                <h1 className='secTitle  text-center' style={{ display: 'flex', justifyContent: 'center', fontSize: '44px', marginTop: '-23px' }}>SEO Services in Guntur-Rank Better. Increase Traffic. Achieve Better ROIs.</h1>
                <h4 style={{ color: 'red' }}>Describe What is SEO for Websites?</h4>
                <p className='container para' style={{ color: 'grey' }}>Creating a website in accordance with search engine criteria in order to attain high ranks and traffic is known as search engine optimisation (SEO).</p>
                <p className='container para' style={{ color: 'grey' }}>Being sent to the back of the class on Google is harmful. There is no allure to being a backbencher in the realm of online firms and e-commerce. Google never rewards shortcut seekers or anyone looking for recognition. If business owners and entrepreneurs disregard SEO and digital marketing, the algorithms and frequent revisions of Google will remain a mystery.</p>
                <h4 style={{ color: 'red' }}>Search engine optimisation (SEO) advantages</h4>
                <ul >
                    <li style={{ color: 'grey' }}>Traffic and leads are generated with the aid of SEO.</li>
                    <li style={{ color: 'grey' }}>Leads produced by SEO have very high rates of conversion and closure.</li>
                    <li style={{ color: 'grey' }}>By generating free visitors instead of spending money on Pay Per Click advertisements, SEO reduces costs.</li>
                    <li style={{ color: 'grey' }}>Local shoppers are drawn to the specific store, shop, or online by top rankings.</li>
                    <li style={{ color: 'grey' }}>Because Google's algorithms place a premium on reliability, if your website comes in first or second through effective SEO, it will assist build your reputation.</li>
                    <li style={{ color: 'grey' }}>With SEO, you may enhance user experience, page navigation, page loading speed, mobile friendliness, and user-updated content.</li>
                    <li style={{ color: 'grey' }}>A website's brand authority and visibility will increase with good SEO.</li>
                    <li style={{ color: 'grey' }}>The SEO method facilitates mentions from other reputable websites.</li>

                </ul>
                <h4 style={{ color: 'red' }}>Digital marketing SEO Strategy</h4>
                <p style={{ color: 'grey' }} className='container para'>Digital marketing strategy and approach are more objective and based on proper research. It is also based on unique client requirements. However, the fundamental process will remain the same for most clients.</p>
                <ul style={{ color: 'grey' }}>
                    <li>Business Analysis</li>
                    <li>Competitor Analysis</li>
                    <li>Keyword Research</li>
                    <li>Website Design Suggestions</li>
                    <li>Content Development</li>
                    <li>On-page Optimization</li>
                    <li>Technical SEO</li>
                    <li>Link Building Strategies</li>
                    <li>Reports</li>

                </ul>
                
                <h4 style={{ color: 'red' }}>Which is Best SEO Company in Guntur</h4>
                <p style={{ color: 'grey' }} className='container para'>Digital Marketing is one of the Best SEO Companies in Guntur. We have been in the SEO Industry for the past 12+ years. We have experienced many Google Updates and are able to change our SEO strategies as per the latest updates.</p>
                <h4 style={{ color: 'red' }}>What type of SEO Strategies your company uses for Ranking?</h4>
                <p style={{ color: 'grey' }} className='container para'>We use 100% White Hat SEO Strategies which are as per guidelines of Google and other search engines.</p>
                <h4 style={{ color: 'red' }}>How much time does it take to rank in Top positions?</h4>
                <p style={{ color: 'grey' }} className='container para'>Search Rankings will totally depends on industry and competition of keywords. Most websites take at least 3-6 months for generating ranking for long-tail keywords. In the long term both medium tail and short tail keywords get ranks.</p>
                <h4 style={{ color: 'red' }}>Can you Guarantee 100% 1st Position on Google?</h4>
                <p style={{ color: 'grey' }} className='container para'>No,We are a professional SEO Company and we don't make promises we cannot deliver. As SEO Rankings are very complex and depend on more than 200+ factors, the results will change dynamically.</p>
                <h4 style={{ color: 'red' }}>What is the Cost of SEO Services in Guntur?</h4>
                <p style={{ color: 'grey' }} className='container para'>Our basic SEO Services price starts .............. ... per keyword/month. The cost will vary depending on industry, competition, and no of keywords. Please contact us for a custom quote for your business.</p>
                <h4 style={{ color: 'red' }}>Do you provide content with SEO Services</h4>
                <p style={{ color: 'grey' }} className='container para'>Content writing is offered as a separate service for complete content change in website or bulk article works. However, we offer free blog articles monthly based on your selected SEO Package.</p>
                <h4 style={{ color: 'red' }}>As Google is integrating AI & Machine Learning algorithms, How will your SEO Services help?</h4>
                <p style={{ color: 'grey' }} className='container para'>Google has added AI & MI algorithms since 2015. These algorithms help Google to simplify the queries and match the content easily. But still the majority of the ranking factors in Google will be dependent on Content & Backlinks. With our SEO Strategies we will make your website SEO Friendly by following all Google guidelines.</p>
              
            </div>

        </>
    )
}

export default SEO_Services_in_Guntur